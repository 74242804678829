import React, { Fragment } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const TooltipIcon = styled.span`
  background-color: ${props => props.theme.tfBlueHover};
  border: 1px solid ${props => props.theme.tfBlue};
  border-radius: 4px;
  color: ${props => props.theme.tfBlue};
  cursor: pointer;
  display: inline-block;
  font-size: 0.9rem;
  height: 21px;
  padding: 0 6px;
  margin-left: 1.5rem;
  width: 21px;
`;

const Tooltip = props => {
  return (
    <Fragment>
      <TooltipIcon data-tip data-for={props.tooltipId}>
        ?
      </TooltipIcon>
      <ReactTooltip effect="solid" id={props.tooltipId}>
        <p style={{ maxWidth: '300px' }}>{props.tooltipText}</p>
      </ReactTooltip>
    </Fragment>
  );
};

export default Tooltip;
