import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import config from '../config.json';
import { formats } from './helpers';
import SimpleContainer from './SimpleContainer';
import GraphicButton from './GraphicButton';
import { GuiIcons, ProfileIcons } from '../images/Images';

const ProfileText = styled.p`
  line-height: ${props => props.theme.fontSize};
  margin: 4px 0;
`;

const ProfileSelect = props => {
  return (
    <div>
      <h2>Select a Profile or Create a Scenario</h2>
      <SimpleContainer rows="3" columns="3" gridGap="1rem">
        <GraphicButton
          onClick={() => {
            props.updateProfile('custom');
            props.updateTaxes(undefined);
          }}
          selected={'custom' === props.selection}
        >
          {GuiIcons.plus()}
          <div>
            <ProfileText>Create a Custom Scenario</ProfileText>
          </div>
        </GraphicButton>
        {config.profiles.map(p => {
          return (
            <GraphicButton
              data-tip={p.description.tooltip ? true : false}
              data-for={`profile-${p.description.id}`}
              key={`profile-${p.description.id}`}
              onClick={() => {
                props.updateProfile(p.description.id);
                props.updateTaxes(p.taxes);
              }}
              selected={p.description.id === props.selection}
            >
              {ProfileIcons[p.description.id]()}
              <div>
                <ProfileText style={{ fontWeight: 700 }}>
                  {p.description.name}
                </ProfileText>
                <ProfileText>{p.description.filingData}</ProfileText>
                <ProfileText>
                  {formats.shortDollar(p.description.income)}
                </ProfileText>
              </div>
              {p.description.tooltip ? (
                <ReactTooltip id={`profile-${p.description.id}`} effect="solid">
                  <p style={{ maxWidth: '300px' }}>{p.description.tooltip}</p>
                </ReactTooltip>
              ) : null}
            </GraphicButton>
          );
        })}
      </SimpleContainer>
    </div>
  );
};

export default ProfileSelect;
