import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from './Tooltip';
import { formats, colorForValue } from './helpers';

const Th = styled.th`
  background-color: #fff;
  border-bottom: 1px solid #999;
  border-left: 1px solid #999;
  padding: 6px 8px;

  &:first-child {
    border-left: 0;
  }
`;

const Td = styled.td`
  border-bottom: 1px solid #999;
  border-left: 1px solid #999;
  padding: 6px 8px;

  &:first-child {
    border-left: 0;
  }
`;

const formatter = (i, value, format, desirable) => {
  if (i === 0) {
    return value;
  } else if (i === 3) {
    return (
      <span style={{ color: colorForValue(value, desirable ? '+' : '-') }}>
        {formats[format](value)}
      </span>
    );
  } else {
    return formats[format](value);
  }
};

const TableRow = props => {
  return (
    <tr>
      {props.cells.map((c, i) => {
        if (props.header) {
          return <Th key={`th-${i}`}>{c.value}</Th>;
        } else {
          let tooltip = undefined;
          let tooltipId = undefined;
          if (c.tooltip) {
            tooltip = true;
            tooltipId = `tooltip-line-item-${props.id}`;
          }

          return (
            <Td
              key={`${props.id}-${i}`}
              style={{
                textAlign: i > 0 ? 'right' : 'left',
                paddingLeft:
                  props.level === 'top'
                    ? '0.5rem'
                    : props.level === 'second'
                    ? '2.5rem'
                    : '4.5rem',
              }}
            >
              {formatter(i, c.value, props.format, props.desirable)}
              {tooltip ? (
                <Tooltip tooltipId={tooltipId} tooltipText={c.tooltip} />
              ) : null}
            </Td>
          );
        }
      })}
    </tr>
  );
};

TableRow.propTypes = {
  cells: PropTypes.array.isRequired,
  header: PropTypes.bool,
  id: PropTypes.string,
  format: PropTypes.string.isRequired,
};

export default TableRow;
