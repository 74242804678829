import React from 'react';
import ReactTooltip from 'react-tooltip';
import { GuiIcons } from '../images/Images';
import SimpleContainer from './SimpleContainer';
import NumericInput from './NumericInput';
import GraphicButton from './GraphicButton';

const DeductionSelect = props => {
  const deductions = [
    { id: 'standardDeduction', name: 'Standard Deduction' },
    {
      id: 'itemize',
      name: 'Itemize Deductions',
      tooltip:
        'This calculator will automatically choose the standard deduction if it is advantageous.',
    },
  ];

  return (
    <div>
      <h2>Standard Deduction or Itemized?</h2>
      <SimpleContainer rows="1" columns="2" gridGap="1rem">
        {deductions.map(d => {
          const tooltipId = d.tooltip ? `tooltip-${d.id}` : false;

          return (
            <GraphicButton
              data-tip={d.tooltip ? true : false}
              data-for={tooltipId}
              key={`deductions-${d.id}`}
              onClick={() => props.updateDeduction(d.id)}
              selected={d.id === props.deduction}
            >
              {GuiIcons[d.id]({ height: 36 })}
              <p style={{ margin: 0 }}>{d.name}</p>
              {d.tooltip ? (
                <ReactTooltip id={tooltipId} effect="solid">
                  <p style={{ maxWidth: '300px' }}>{d.tooltip}</p>
                </ReactTooltip>
              ) : null}
            </GraphicButton>
          );
        })}
      </SimpleContainer>
      {props.deduction === 'itemize' ? (
        <div>
          <SimpleContainer rows="1" columns="2" gridGap="1rem">
            <NumericInput
              id="medical_expenses"
              format="shortDollar"
              name="Medical Expenses"
              onChange={props.updateInput}
              onEnter={props.calculate}
              placeholder="0"
            />
            <NumericInput
              id="sl_property_tax"
              format="shortDollar"
              name="State and Local Property Taxes"
              onChange={props.updateInput}
              onEnter={props.calculate}
              placeholder="0"
            />
          </SimpleContainer>
          <SimpleContainer rows="1" columns="2" gridGap="1rem">
            <NumericInput
              id="interest_paid"
              format="shortDollar"
              name="Mortgage Interest"
              onChange={props.updateInput}
              onEnter={props.calculate}
              placeholder="0"
              tooltip="For the sake of simplicity, this calculator will limit the mortgage interest deduction to $40,000 under prior law and $30,000 under the TCJA. These limits were imputed using an assumed mortgage interest rate of 4%."
            />
            <NumericInput
              id="sl_income_tax"
              format="shortDollar"
              name="State and Local Income Taxes"
              onChange={props.updateInput}
              onEnter={props.calculate}
              placeholder="0"
              tooltip="If the household chooses to deduct sales taxes instead of income taxes, enter the deduction for state and local sales taxes paid here."
            />
          </SimpleContainer>
          <SimpleContainer rows="1" columns="2" gridGap="1rem">
            <NumericInput
              id="charity_contributions"
              format="shortDollar"
              name="Charitable Contributions"
              onChange={props.updateInput}
              onEnter={props.calculate}
              placeholder="0"
            />
            <NumericInput
              id="other_itemized"
              format="shortDollar"
              name="Other Itemized Deductions"
              onChange={props.updateInput}
              onEnter={props.calculate}
              placeholder="0"
              tooltip="Line 28 from Schedule A."
            />
          </SimpleContainer>
        </div>
      ) : null}
    </div>
  );
};

export default DeductionSelect;
