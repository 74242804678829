import { format } from 'd3-format';

const formats = {
  shortDollar: format('$,'),
  moarDollar: format('$,.2f'),
  deltaDollar: format('+$,.2f'),
  percent: format('.2%'),
  text: text => text.charAt(0).toUpperCase() + text.slice(1)
};

const colorForValue = (value, sign) => {
  let goodOrBadSign = sign === '+' ? 1 : -1;
  if (value * goodOrBadSign > 0) {
    return '#00aa22';
  } else if (value * goodOrBadSign < 0) {
    return '#EF4438';
  } else {
    return '#333';
  }
};

export { formats, colorForValue };
