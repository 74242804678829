const Theme = {
  fontFamily: '"Lato", sans-serif',
  fontSize: '16px',
  mobileWidth: '480px',
  tfBlue: '#0094ff',
  tfBlueHighlight: '#4db4ff',
  tfBlueHover: '#e6f4ff',
};

export default Theme;
