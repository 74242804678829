/**
 * Comparison Summary Boxes Component
 * @module components/ComparisonSummary
 */

import React, { Component, Fragment } from 'react';
import styled, { keyframes } from 'styled-components';
import SimpleContainer from './SimpleContainer';
import TaxTable from './TaxTable';
import SocialButtons from './SocialButtons';
import ColorizeNumber from './ColorizeNumber';
import { formats, colorForValue } from './helpers';

const Animation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  animation: ${Animation} 0.6s;
`;

/**
 * Box containing the summary of a particular tax burden.
 */
const SummaryBoxStyle = styled.div`
  background-color: ${props =>
    props.primary ? props.theme.tfBlueHover : '#fff'};
  display: grid;
  grid-template: auto 1fr / auto;
  text-align: center;
`;

const SummaryBoxTitle = styled.div`
  background-color: ${props => (props.color ? props.color : '#fff')};
  border: 1px solid ${props => (props.color ? props.color : '#999')};
  color: ${props => (props.color ? '#fff' : '#333')};
  font-size: ${props => (props.main ? '18px' : '14px')};
  font-weight: 400;
  line-height: ${props => (props.main ? '40px' : '24px')};
  margin: 0;
  padding: 0.5rem 1rem;
`;

const SummaryBoxValue = styled.div`
  border: 1px solid #999;
  border-top: 0;
  font-size: ${props => (props.main ? 'calc(20px + 1vw)' : '20px')};
  line-height: ${props => (props.main ? '40px' : '24px')};
  padding: ${props => (props.main ? '24px' : '12px')} 0;

  @media (min-width: 720px) {
    font-size: ${props => (props.main ? '32px' : '20px')};
  }
`;

const SummaryBox = props => {
  return (
    <SummaryBoxStyle>
      <SummaryBoxTitle main={props.main} color={props.color}>
        {props.title}
      </SummaryBoxTitle>
      <SummaryBoxValue main={props.main}>{props.value}</SummaryBoxValue>
    </SummaryBoxStyle>
  );
};

/**
 * Exported component that is rendered to the page. Includes English summary
 * as well as comparison boxes.
 * @param {Object} props Properties to compute and render
 * @returns {string} HTML component string
 */
class ComparisonSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoar: false,
    };

    this.updateShowMoar = this.updateShowMoar.bind(this);
  }

  updateShowMoar() {
    let newShow = !this.state.showMoar;
    this.setState({ showMoar: newShow });
  }

  componentDidMount() {
    this.props.scroll(this.refs.summary);
  }

  render() {
    const plan1 = this.props.plan1;
    const plan2 = this.props.plan2;
    const burdenDelta = plan2.results.tax_wedge - plan1.results.tax_wedge;
    const takeHomeDelta =
      plan2.results.take_home_pay - plan1.results.take_home_pay;
    let afterTaxPercentDelta = takeHomeDelta / plan1.results.take_home_pay;

    if (isNaN(afterTaxPercentDelta)) {
      afterTaxPercentDelta = 0;
    }

    return (
      <Fragment>
        <Container>
          <h2 ref="summary">{`${this.props.year} Tax Liability`}</h2>
          <SimpleContainer rows="1" columns="3" gridGap="1rem">
            <SummaryBox
              title={plan1.plan.name}
              value={formats.moarDollar(plan1.results.tax_wedge)}
              main
            />
            <SummaryBox
              title={plan2.plan.name}
              value={formats.moarDollar(plan2.results.tax_wedge)}
              color="#0094ff"
              main
            />
            <SummaryBox
              title={`Tax ${burdenDelta <= 0 ? 'Cut' : 'Increase'}`}
              value={
                <ColorizeNumber sign="-" value={burdenDelta}>
                  {formats.deltaDollar(burdenDelta)}
                </ColorizeNumber>
              }
              color={colorForValue(burdenDelta, '-')}
              main
            />
          </SimpleContainer>
          <SimpleContainer rows="1" columns="2" gridGap="1rem">
            <SummaryBox
              title="% Change in Take-Home Pay"
              value={
                <ColorizeNumber sign="+" value={afterTaxPercentDelta}>
                  {formats.percent(afterTaxPercentDelta)}
                </ColorizeNumber>
              }
            />
            <SummaryBox
              title="Share the Results"
              value={
                <SocialButtons
                  size="25px"
                  message="I used this new tax reform calculator to see how the Tax Cuts and Jobs Act will impact after-tax incomes."
                  hashtags="TaxReform"
                  emailSubject="Tax Reform Calculator"
                  emailBody={`The Tax Foundation's tax reform calculator shows you how the Tax Cuts and Jobs Act will impact after-tax income, total taxes owed, effective tax rates, and more. You can calculate a custom scenario here: ${
                    window.location.href
                  }`}
                />
              }
            />
          </SimpleContainer>
          <p style={{ fontSize: 12 }}>
            The <a href="https://taxfoundation.org">Tax Foundation</a>'s tax
            calculator is for estimation of the impact of the TCJA on taxpayers.
            It does not fully represent all potential tax scenarios and
            liabilities, and should not be used for tax preparation purposes.
            This calculator is for educational use only.
          </p>
        </Container>
        <Container>
          <TaxTable
            // TODO replace with manually selected plans
            plan1={this.props.plan1}
            plan2={this.props.plan2}
            resultsMeta={this.props.resultsMeta}
          />
        </Container>
      </Fragment>
    );
  }
}

export default ComparisonSummary;
