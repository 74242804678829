import React from 'react';
import styled from 'styled-components';

const ProfileIcons = {
  james: props => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.91 42.75"
      >
        <path
          d="M0 38.75v-1c0-5.1-.2-9.9 10.5-10.8v-4.1h5.9v4.1c11.2.9 10.5 6.2 10.5 11.5v.3a24.61 24.61 0 0 1-26.9 0z"
          fill="#fdcd9a"
        />
        <path
          d="M10.5 22.75v.1c1.3.8 2.9 1.3 2.9 4.7v15a24.47 24.47 0 0 0 13.4-4V36.75c0-4.7-.5-9-10.5-9.8v-4.1h-5.8z"
          fill="#c79462"
        />
        <path
          d="M0 38.75v-1c0-5.1-.2-9.9 10.5-10.8a4.53 4.53 0 0 0 5.8 0c11.2.9 10.5 6.2 10.5 11.5v.3a24.72 24.72 0 0 1-13.4 4 24.47 24.47 0 0 1-13.4-4z"
          fill="#b9b5ac"
        />
        <path
          d="M8.1 27.25c6.3 2.2 13.3 6 13 14.3a29.74 29.74 0 0 0 5.7-2.7v-.3c0-5.3.7-10.6-10.5-11.5a4.53 4.53 0 0 1-5.8 0 6.17 6.17 0 0 0-2.4.2z"
          fill="#8d8980"
        />
        <path
          d="M5.1 12.15c-1-.1-.8 5.8.9 5.9 1.4 3 3.8 5.6 7.4 5.6s6.1-2.6 7.4-5.6c1.5 0 1.8-6 .8-5.9.4-6.4.2-10.4-8.2-10.4s-8.7 3.8-8.3 10.4z"
          fill="#fdcd9a"
        />
        <path
          d="M9.9 22.65c2 .5 5.1 1.2 7.5-1.8s1.7-9.6.6-14.1a8.51 8.51 0 0 0-3.1-4.9 12.7 12.7 0 0 1 3.9.8c3.1 1.5 3.1 4.9 2.9 9.5 1-.1.7 5.9-.8 5.9-1.3 2.8-3.5 5.2-6.6 5.6h-1.5a6.3 6.3 0 0 1-2.9-1z"
          fill="#e0b082"
        />
        <path
          d="M10.6 18.75a2.77 2.77 0 0 0-.3 4.2c1.2 1.4 4.9 1.4 6.1 0a2.92 2.92 0 0 0-.3-4.2c-.9-.7-4.6-.7-5.5 0zm2.1 1.6c-.2.1 0 1.1-1.3 1.1-.6 0-.8-.7-.8-1.3s.3-1.3 1-1a2.56 2.56 0 0 0 1.7.1 1.74 1.74 0 0 0 1.7-.1c.7-.2 1 .5 1 1s-.2 1.2-.8 1.2c-1.3 0-1-.9-1.3-1.1a1.73 1.73 0 0 0-1.2.1zM5.3 1.35c-.9 1.9-.8 6.5-.2 10.7 1.3-.1 1.5.9 1.5 2.3a9.23 9.23 0 0 0 .5-3.7c-.3-2.6.3-5.1 3.1-4.4a9.71 9.71 0 0 0 6.3 0c2.8-.8 3.4 1.7 3.1 4.4a7.76 7.76 0 0 0 .5 3.7c0-1.5.3-2.4 1.6-2.3.6-4.2.7-8.8-.2-10.7s-15.3-1.8-16.2 0z"
          fill="#795028"
        />
      </svg>
    );
  },
  jason: props => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28.4 45.01"
      >
        <path
          d="M0 40.81v-1c0-5.4-.2-10.5 11.1-11.4v-4.4h6.2v4.4c11.8 1 11 6.5 11.1 12.1v.3a25.41 25.41 0 0 1-14.2 4.2A25.89 25.89 0 0 1 0 40.81z"
          fill="#98644f"
        />
        <path
          d="M11.1 24.01v.1c1.4.9 3 1.4 3.1 5v15.8a26.15 26.15 0 0 0 14.1-4.2v-2c0-4.9-.5-9.4-11.1-10.3v-4.4z"
          fill="#69351f"
        />
        <path
          d="M22.9 12.81c1-.1.9 6.1-.9 6.2-1.5 3.2-4.1 5.9-7.8 5.9s-6.4-2.7-7.9-5.9c-1.6 0-1.9-6.3-.9-6.2-.3-6.7 0-10.9 8.8-10.9s9.2 3.9 8.7 10.9z"
          fill="#98644f"
        />
        <path
          d="M10.5 23.91c2.3.8 5.5 1.1 7.6-1.7 2.6-3.4 2.3-10.7.8-15-.6-1.7-1.5-4.1-3.1-5.2 7.2.5 7.5 4.4 7.1 10.9 1-.1.9 6.1-.9 6.2a10.82 10.82 0 0 1-3.7 4.7 7.07 7.07 0 0 1-4.1 1.2 8.89 8.89 0 0 1-3.7-1.1z"
          fill="#824e39"
        />
        <path
          d="M0 40.81v-1c0-5.4-.2-10.5 11.1-11.4a16.17 16.17 0 0 0 6.2 0c11.8 1 11 6.5 11.1 12.1v.3a25.41 25.41 0 0 1-14.2 4.2A25.89 25.89 0 0 1 0 40.81z"
          fill="#8ea287"
        />
        <path
          d="M22.4 43.71a24.9 24.9 0 0 0 5.9-2.9v-.3c0-5.6.7-11.1-11.1-12.1a16.17 16.17 0 0 1-6.2 0c1.1 1 2.2 1.9 3.3 2.8 3.8 2.8 8.2 6.5 8.1 12.5z"
          fill="#71856a"
        />
        <path
          d="M12.4 44.91l1.1-13-1-1.9 1.6-1.3 1.6 1.3-1 1.9 1.1 13c-.6 0-1.2.1-1.8.1a9.74 9.74 0 0 1-1.6-.1z"
          fill="#010101"
        />
        <path
          d="M18.3 28.51l-.9 4-3.2-3-3.3 3-.9-4c.3 0 .7-.1 1.1-.1a16.17 16.17 0 0 0 6.2 0 3.4 3.4 0 0 1 1 .1z"
          fill="#bacdb3"
        />
        <path
          d="M20.5 12.41v2.9h.7c0-1 .1-1.8.7-2.2a1.45 1.45 0 0 0 .7.2 1.32 1.32 0 0 0 1.3-1.3 1.23 1.23 0 0 0-.7-1.1 1.4 1.4 0 0 0 .9-1.2 1.52 1.52 0 0 0-.7-1.2 1.39 1.39 0 0 0 .7-1.2 1.29 1.29 0 0 0-.9-1.2 1.22 1.22 0 0 0 .3-.9 1.3 1.3 0 0 0-1.2-1.3.9.9 0 0 0 .1-.5 1.32 1.32 0 0 0-1.3-1.3 1.27 1.27 0 0 0-.6.1 1.32 1.32 0 0 0-1.3-1.3 1.85 1.85 0 0 0-.7.2 1.4 1.4 0 0 0-1.2-.9 1.78 1.78 0 0 0-1 .4 1.11 1.11 0 0 0-1.1-.6 1.23 1.23 0 0 0-1 .5 1.23 1.23 0 0 0-1-.5 1.52 1.52 0 0 0-1.2.7 1.16 1.16 0 0 0-1-.4 1.29 1.29 0 0 0-1.2.9 1.45 1.45 0 0 0-.7-.2 1.32 1.32 0 0 0-1.3 1.3 1.27 1.27 0 0 0-.6-.1 1.32 1.32 0 0 0-1.3 1.3.75.75 0 0 0 .1.5 1.24 1.24 0 0 0-1.2 1.28 1.14 1.14 0 0 0 .3.8v.1a1.3 1.3 0 0 0-.8 1.2 1.15 1.15 0 0 0 .6 1.1v.1a1.15 1.15 0 0 0-.6 1.1 1.3 1.3 0 0 0 .8 1.2 1.15 1.15 0 0 0-.6 1.12 1.32 1.32 0 0 0 1.3 1.3 1.85 1.85 0 0 0 .7-.2 2.64 2.64 0 0 1 .7 2.2h.7v-2.9a1.16 1.16 0 0 0 .4-1 1.4 1.4 0 0 0-.3-.9 1.15 1.15 0 0 0 .6-1.1 1.07 1.07 0 0 0-.2-.6h.2a1.2 1.2 0 0 0 1.2-.9 1.16 1.16 0 0 0 1 .4 1.39 1.39 0 0 0 1.2-.7 1.27 1.27 0 0 0 1.2.7 1.23 1.23 0 0 0 1-.5 1.23 1.23 0 0 0 1 .5 1.39 1.39 0 0 0 1.2-.7 1.39 1.39 0 0 0 1.2.7 1.78 1.78 0 0 0 1-.4 1.4 1.4 0 0 0 1.2.9h.2a1.42 1.42 0 0 0-.2.6 1.36 1.36 0 0 0 .6 1.1 1.22 1.22 0 0 0-.3.9 1.16 1.16 0 0 0 .4 1z"
          fill="#010101"
        />
        <path
          d="M13.12 13.87l-4-.2h-.06a.22.22 0 0 0-.11.05h.16l4 .2a.32.32 0 0 1 .3.3l-.1 1.7a.77.77 0 0 1 0 .15.47.47 0 0 0 0-.17l.1-1.7a.32.32 0 0 0-.29-.33zm6.08-.46l-4 .1c-.4 0-.7.4-.8.8v.1a.64.64 0 0 0-.6 0v-.1a.77.77 0 0 0-.7-.8l-4-.1a.77.77 0 0 0-.7.8l.1 1.9c.1.5.3.8.7.8H13a.77.77 0 0 0 .7-.8l.1-1.2a.85.85 0 0 1 .7 0l.1 1.2c.1.4.3.8.7.8h3.8a.77.77 0 0 0 .7-.8l.1-1.9v-.09a.71.71 0 0 0-.7-.71zm-5.91 2.6a.29.29 0 0 1-.27.22h-3.8c-.2 0-.3-.2-.3-.4l-.1-1.82a.25.25 0 0 1 0-.12.24.24 0 0 1 .18-.17h.16l4 .2a.32.32 0 0 1 .3.3l-.1 1.7a.77.77 0 0 1 0 .15zm6.11-.14c0 .2-.1.4-.3.4h-3.8c-.2 0-.3-.2-.3-.4l-.1-1.7a.32.32 0 0 1 .3-.3l4-.2h.06a.27.27 0 0 1 .24.3z"
          fill="#010101"
        />
        <path
          d="M13.29 16.01a.3.3 0 0 1-.28.24h-3.8c-.2 0-.3-.2-.3-.4l-.1-1.84a.17.17 0 0 1 0-.14.25.25 0 0 0 0 .12l.1 1.9c0 .2.1.4.3.4H13a.29.29 0 0 0 .29-.28z"
          fill="#010101"
        />
      </svg>
    );
  },
  amber: props => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25.81 40.4"
      >
        <path
          d="M12.9 12c5.2 0 9.3 5.9 9.3 13.1a16.84 16.84 0 0 1-.5 4H4a17.52 17.52 0 0 1-.5-4C3.6 17.9 7.8 12 12.9 12z"
          fill="#010101"
        />
        <path
          d="M0 36.6v-.9c0-4.9-.2-9.5 10.1-10.4v-4h5.6v4c10.8.9 10.1 6 10.1 11v.3a23.8 23.8 0 0 1-25.8 0z"
          fill="#fdcd9a"
        />
        <path
          d="M4.4 26.4L3.1 38.3a22.87 22.87 0 0 0 9.7 2.1L9 25.4a16.64 16.64 0 0 0-4.6 1zm18.3 11.9l-1.3-11.9a16.46 16.46 0 0 0-4.5-1.1l-3.9 15a21.94 21.94 0 0 0 9.7-2z"
          fill="#991b1e"
        />
        <path
          d="M15.9 29.1a11.77 11.77 0 0 1 4.9 9.9 15.39 15.39 0 0 0 1.9-.8l-1.3-11.9a16.46 16.46 0 0 0-4.5-1.1l-1 3.9z"
          fill="#761113"
        />
        <path
          d="M10.1 22.2a21.25 21.25 0 0 1 5.6 1.9v-2.8h-5.6v.9z"
          fill="#ca976a"
        />
        <path
          d="M20.9 11.1c1-.1.8 5.6-.8 5.6-1.3 2.9-3.7 5.4-7.1 5.4s-5.8-2.5-7.2-5.4C4.3 16.7 4 11 5 11.1c-.3-6.1-.1-10 7.9-10s8.3 3.6 8 10z"
          fill="#fdcd9a"
        />
        <path
          d="M9.5 21.2a5.5 5.5 0 0 0 6.9-1.7c2.4-3 2.3-9.8.8-14a8.53 8.53 0 0 0-2.8-4.3c6.5.5 6.8 4 6.5 9.9 1-.1.8 5.6-.8 5.6a10.07 10.07 0 0 1-3.4 4.2A6.71 6.71 0 0 1 13 22a6.71 6.71 0 0 1-3.5-.8z"
          fill="#e0b082"
        />
        <path
          d="M12.9 0c8.7 0 11.1 8.4 8.2 15.4.4-1.6.2-3.6-.6-3.6l-7.7-5.3-7.6 5.3c-.8 0-.9 1.8-.6 3.3C2 8.3 4.3 0 12.9 0z"
          fill="#010101"
        />
      </svg>
    );
  },
  kavya: props => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 47.3 43.6"
      >
        <path
          d="M18.9 38.8c0-8.6 1.3-11.5 11.1-12.4v-4.3h6.2v4.3c9.9.8 11 3.7 11.1 12.4A25.6 25.6 0 0 1 33.7 43h-.5a25 25 0 0 1-14.3-4.2z"
          fill="#dfad7f"
        />
        <path
          d="M30 22.1v.1c1.4.9 3 1.4 3.1 5V43a26.05 26.05 0 0 0 14.1-4.1c-.2-7.3-.1-11.6-11-12.5v-4.3H30z"
          fill="#b27f53"
        />
        <path
          d="M41.8 11c1-.1.9 6.1-.9 6.2-1.4 3.2-4 5.9-7.8 5.9s-6.4-2.7-7.8-5.9c-1.6 0-1.9-6.3-.9-6.2C24 4.3 24.3.1 33.1.1S42.2 4 41.8 11z"
          fill="#dfad7f"
        />
        <path
          d="M29.4 22c2.2.9 5.4 1.3 7.9-1.8 2.6-3.3 1.9-9.9.5-14.7A12.89 12.89 0 0 0 34.7.1c7.2.5 7.5 4.4 7.1 10.8 1-.1.9 6.1-.9 6.2a10 10 0 0 1-3.7 4.6 7.07 7.07 0 0 1-4.1 1.2 5.85 5.85 0 0 1-3.7-.9z"
          fill="#c7956d"
        />
        <path
          d="M26.1 13.4c0-1.5-.3-2.5-1.7-2.5C22.9 3.5 24.7 0 33.1 0s10 3.5 8.7 10.9c-1.4-.1-1.7.9-1.7 2.5l-1.5-7.8-8.3 4.8.3-3.3-4.5 6.3z"
          fill="#4c483f"
        />
        <path
          d="M36.1 26.5l2.5.3-3.9 15.4-1.4.4-1.9-.5-3.9-15.3 2.5-.3z"
          fill="#dfdfde"
        />
        <path
          fill="#f0542e"
          d="M31.4 27.9l1 2.1-.8 5.9.5 6.2h2.3l.1-6.2-.7-5.9.9-2.1-1.6-1-1.7 1z"
        />
        <path
          d="M30 26.5l3.1 15.2 3.1-15.2c10.1.8 10.9 3.8 11.1 12.5a25.49 25.49 0 0 1-13.6 4.1h-1a26.28 26.28 0 0 1-13.6-4.2c-.1-8.8.8-11.6 10.9-12.4z"
          fill="#641010"
        />
        <path
          d="M35.3 30.7l.9-4.2c10.1.8 10.9 3.8 11.1 12.5a27.66 27.66 0 0 1-6 2.9 13.51 13.51 0 0 0-6-11.2z"
          fill="#3f1212"
        />
        <path fill="#fff" d="M36.1 26.5l.7 2.9-3.7-2.4-3.7 2.4.6-2.9h6.1z" />
        <path
          d="M5 10c-.2 1-.4 2-.5 3-.9 6.1-.3 12.9-3.5 14.4a3.45 3.45 0 0 0 2.8-.6C3 29.3 2 30.2 0 31.6c3.2 3.8 27.5 4 31.4 0-2-1.4-3-2.3-3.8-4.8a3.56 3.56 0 0 0 2.8.7c-3.3-1.5-2.7-8.3-3.5-14.4a15.67 15.67 0 0 0-.5-3 9.9 9.9 0 0 0-7.1-7.4 9.36 9.36 0 0 0-1.6-.4 10.87 10.87 0 0 0-1.8-.1h-.3a10.87 10.87 0 0 0-1.8.1 7.49 7.49 0 0 0-1.6.4A9.71 9.71 0 0 0 5 10z"
          fill="#010101"
        />
        <path
          d="M2.7 39.7v-.2c0-4.9-.2-9.6 10.2-10.5v-4h5.7v4c10.5.9 10.2 5.7 10.2 10.7a23.48 23.48 0 0 1-13.1 3.9 24.84 24.84 0 0 1-13-3.9z"
          fill="#99663c"
        />
        <path
          d="M2.7 39.7v-.2c0-3.8-.1-7.5 4.7-9.4a15.06 15.06 0 0 0 8.3 2.3 14.81 14.81 0 0 0 8.3-2.3c4.9 1.9 4.7 5.7 4.7 9.6a23.48 23.48 0 0 1-13.1 3.9 24.76 24.76 0 0 1-12.9-3.9z"
          fill="#fcf9ce"
        />
        <path
          d="M12.9 26a20.87 20.87 0 0 1 5.7 1.9V25h-5.7v1z"
          fill="#723f19"
        />
        <path
          d="M7.7 14.6c-1 0-.8 5.7.8 5.7 1.3 2.9 3.7 5.5 7.2 5.5s5.9-2.5 7.2-5.5c1.5 0 1.8-5.7.8-5.7.3-6.2-2.3-8.8-8-8.8s-8.4 2.4-8 8.8z"
          fill="#99663c"
        />
        <path
          d="M12.3 24.8c2 .5 4.9 1.1 7.2-1.7 2.7-3.3 1.8-13.5-.9-17 3.7.8 5.4 3.4 5.1 8.5 1 0 .7 5.7-.8 5.7-1.3 3-3.7 5.5-7.2 5.5a6.46 6.46 0 0 1-3.4-1z"
          fill="#845023"
        />
        <path
          d="M7.2 14.7c2.1 0 3.4-.3 4.8-2.2a2.34 2.34 0 0 1-1.2 1.5 6.23 6.23 0 0 0 3.6-1.6 4 4 0 0 1-1.6 1.6c2.3.4 6-1 7-2.6.8 1.9 2.9 3.5 4.4 3.5 1.1-3.4 0-9.3-6.4-10.2-7.5-1-10.8 3.1-10.6 10z"
          fill="#010101"
        />
        <path
          d="M19.7 31.9a12.13 12.13 0 0 1 3.8 10.3 19.49 19.49 0 0 0 5.2-2.6c0-3.9.2-7.7-4.7-9.6a10.63 10.63 0 0 1-4.3 1.9z"
          fill="#d8d8b1"
        />
      </svg>
    );
  },
  sophie: props => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 43.6 43.1"
      >
        <path
          d="M15.5 38.9v-1c0-5.3-.2-10.4 11-11.3v-4.3h6.1v4.3c11.7 1 10.9 6.5 11 12v.3a26.07 26.07 0 0 1-14 4.2 26.76 26.76 0 0 1-14.1-4.2z"
          fill="#c48d59"
        />
        <path
          d="M26.5 22.2v.1c1.4.9 3 1.4 3.1 4.9v15.7a25 25 0 0 0 14-4.2V36.9c0-4.9-.5-9.4-11-10.3v-4.3h-6.1z"
          fill="#ab7340"
        />
        <path
          d="M38.2 11.1c1-.1.9 6.1-.9 6.1-1.4 3.2-4 5.9-7.8 5.9s-6.3-2.7-7.8-5.9c-1.6 0-1.9-6.2-.9-6.1C20.4 4.4 20.7.3 29.4.3s9.2 3.9 8.8 10.8z"
          fill="#c48d59"
        />
        <path
          d="M25.9 22.1c2.2.9 4.2.7 6.6-2.4 2.6-3.3 2.9-9.6 1.3-14.3-.6-1.7-1.1-4-2.7-5.1 7.1.5 7.4 4.3 7 10.8 1-.1.9 6.1-.9 6.1a11.14 11.14 0 0 1-3.7 4.6 7.07 7.07 0 0 1-4.1 1.2 6.12 6.12 0 0 1-3.5-.9z"
          fill="#b77f4f"
        />
        <path
          d="M15.5 38.8c.1-5.6-1-11.3 11-12.3a6 6 0 0 0 6.1 0c12 1 10.9 6.7 11 12.3a25.64 25.64 0 0 1-14 4.2h-2.2a26.1 26.1 0 0 1-11.9-4.2z"
          fill="#010101"
        />
        <path
          d="M35.8 11.1c0 2.9-.4 9.9-4.5 8.7a6.92 6.92 0 0 0-3.5 0c-4 1.3-4.5-6-4.5-8.7-.1-2.9 1.1-2.1 1.7-4.9 4.6 2.1 10.8-1 10.8 4.9zm2.4 0C39 3.6 37.9 0 29.5 0s-9.8 3.6-8.6 11.1c2.7-.1 1.5 5.8.9 6.1 3.7 9.3 12.2 9.4 15.5 0-.8-1.3-1.8-6.2.9-6.1z"
          fill="#010101"
        />
        <path
          d="M4.7 33.3c3.8-2.6-1.1-6.5-3-14.5C.4 13.4 3.7-.8 12.4 2.9c8.8-3.8 12.2 10.6 10.9 16-1.9 8-5.5 11.4-1.6 14-4.8 1.1-8.6 2.4-17 .4z"
          fill="#010101"
        />
        <path
          d="M0 39.3v-.2c0-4.7-.2-9.2 9.8-10.1v-3.8h5.4V29c10.1.9 9.8 5.5 9.8 10.2a23.22 23.22 0 0 1-12.5 3.7A21.55 21.55 0 0 1 0 39.3z"
          fill="#98644f"
        />
        <path
          d="M0 39.3v-.2c0-3.7-.1-7.2 4.5-9a14.82 14.82 0 0 0 8 2.2 14 14 0 0 0 8-2.2c4.7 1.8 4.5 5.4 4.5 9.2A23.22 23.22 0 0 1 12.5 43 22.39 22.39 0 0 1 0 39.3z"
          fill="#dc5526"
        />
        <path
          d="M9.8 26.2a22.38 22.38 0 0 1 5.4 1.8v-2.8H9.8v1z"
          fill="#6e3b26"
        />
        <path
          d="M4.8 15.3c-.9 0-.8 5.4.8 5.5 1.3 2.8 3.6 5.2 6.9 5.2s5.6-2.4 6.9-5.2c1.4 0 1.7-5.5.8-5.5.3-6-2.2-8.4-7.6-8.4s-8.2 2.3-7.8 8.4z"
          fill="#98644f"
        />
        <path
          d="M9.2 25.1c1.9.5 4.7 1.1 6.9-1.7s1.6-8.9.6-13.1a7.49 7.49 0 0 0-1.5-3.1c3.5.8 5.2 3.3 4.9 8.1 1 0 .7 5.5-.8 5.5-1.3 2.8-3.6 5.2-6.9 5.2a6.2 6.2 0 0 1-3.2-.9z"
          fill="#824e39"
        />
        <path
          d="M22.8 31.4c-5.2 4.4-15.3 4.4-20.5 0a8.77 8.77 0 0 1 4-1.8 13.77 13.77 0 0 0 12.5 0 10 10 0 0 1 4 1.8z"
          fill="#ffda61"
        />
        <path
          d="M16.1 32c2.4 2.6 4 5.8 3.3 10a19.33 19.33 0 0 0 5.6-2.7c0-3.7.2-7.3-4.5-9.2a11.75 11.75 0 0 1-4.4 1.9z"
          fill="#bb3426"
        />
        <path
          d="M15 30.9a16.37 16.37 0 0 1 2.8 3.1 13.22 13.22 0 0 0 5-2.6 8.77 8.77 0 0 0-4-1.8 13.16 13.16 0 0 1-3.8 1.3z"
          fill="#dfb93b"
        />
        <path
          d="M20.4 15.2a25.42 25.42 0 0 1-15.9 0c0-14.3 15.9-14.1 15.9 0z"
          fill="#010101"
        />
      </svg>
    );
  },
  soren: props => {
    return (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.91 41">
        <path
          d="M15.2 37.1v-1c0-5-.2-9.9 10.4-10.8v-4.1h5.8v4.1c11.1.9 10.4 6.2 10.4 11.4v.3a24.37 24.37 0 0 1-26.6.1z"
          fill="#fdcd9a"
        />
        <path
          d="M25.7 21.2v.1c1.3.8 2.8 1.3 2.9 4.7v14.9a24.39 24.39 0 0 0 13.3-4V35.1c0-4.7-.5-8.9-10.4-9.7v-4.1h-5.8z"
          fill="#c79462"
        />
        <path
          d="M36.8 10.7c1-.1.8 5.8-.9 5.8-1.4 3-3.8 5.6-7.4 5.6s-6-2.6-7.4-5.6c-1.5 0-1.8-5.9-.8-5.8C20 4.4 20.2.4 28.5.4s8.7 3.7 8.3 10.3z"
          fill="#fdcd9a"
        />
        <path
          d="M25.1 21.2c2.1.8 5.1 1.1 7.4-1.8 2.5-3.1 2.2-9.6.6-14-.5-1.7-1.5-3.9-3-4.9 6.8.5 7.1 4.1 6.7 10.2 1-.1.8 5.8-.9 5.8a10.26 10.26 0 0 1-3.5 4.4 7.2 7.2 0 0 1-3.9 1.2 7.81 7.81 0 0 1-3.4-.9z"
          fill="#e0b082"
        />
        <path
          d="M15.2 37.1v-1c0-5-.2-9.9 10.4-10.8a4.53 4.53 0 0 0 5.8 0c11.1.9 10.4 6.2 10.4 11.4v.3a24.37 24.37 0 0 1-26.6.1z"
          fill="#71c2d5"
        />
        <path
          d="M23.3 25.6c6.2 2.2 13.2 6 12.9 14.2a29.74 29.74 0 0 0 5.7-2.7v-.3c0-5.3.7-10.5-10.4-11.4a4.53 4.53 0 0 1-5.8 0 16.59 16.59 0 0 0-2.4.2z"
          fill="#5091a7"
        />
        <path
          d="M20.3 10.7c-1-.1-.8 5.8.9 5.8 1.4 3 3.8 5.6 7.4 5.6s6-2.6 7.4-5.6c1.5 0 1.8-5.9.8-5.8.4-6.3.1-10.3-8.2-10.3s-8.7 3.7-8.3 10.3z"
          fill="#f4c397"
        />
        <path
          d="M25.1 21.1c2 .5 5.1 1.2 7.4-1.8 2.5-3.1 1.7-9.5.6-14A8.84 8.84 0 0 0 30 .4c6.9.5 7 4.3 6.7 10.2 1-.1.7 5.8-.8 5.8-1.4 3-3.9 5.6-7.4 5.6a5.61 5.61 0 0 1-3.4-.9z"
          fill="#e0b082"
        />
        <path
          d="M24.4 6.7l3.4 2.4-.6-2.3 3.5 2.7-1-3.1 3.2 3.5-.6-3.8 2.9 6.8c0-1.5.2-2.4 1.6-2.3C38 2.2 36.5 0 28.6 0s-9.8 2.7-8.2 10.7c1.3-.1 1.6.9 1.6 2.3l2.4-6.3z"
          fill="#010101"
        />
        <path
          d="M28.9 12.4a.52.52 0 0 0-.4-.2.76.76 0 0 0-.4.2v.4c0 1.5-1 1.9-2.3 1.9a2.43 2.43 0 0 1-2.4-1.4 2.92 2.92 0 0 1-.2-.9c0-1.5.7-1.9 2.1-1.9A3 3 0 0 1 28 12a.72.72 0 0 1 1 0 2.84 2.84 0 0 1 2.7-1.5c1.4 0 2.1.4 2.1 1.9a2.92 2.92 0 0 1-.2.9 2.43 2.43 0 0 1-2.4 1.4c-1.4 0-2.3-.4-2.3-1.9v-.4zm2.8-1.4a2.35 2.35 0 0 0-1.6.5 1.45 1.45 0 0 0-.7 1v.4a1.34 1.34 0 0 0 .2.9 2.11 2.11 0 0 0 1.7.6 2 2 0 0 0 2.1-1.2 1.27 1.27 0 0 0 .1-.6 1.8 1.8 0 0 0-.2-1 1.74 1.74 0 0 0-1.6-.6zm-6.3 0c-.5 0-1.2 0-1.5.5a1.8 1.8 0 0 0-.2 1 1.27 1.27 0 0 0 .1.6 2 2 0 0 0 2.1 1.2c.6 0 1.4-.1 1.7-.6a1.49 1.49 0 0 0 .2-.9v-.4a1.83 1.83 0 0 0-.7-1 3.53 3.53 0 0 0-1.7-.4z"
          fill="#010101"
        />
        <path
          d="M12.7 15.6a6.58 6.58 0 0 1 6.7 6.4 6.58 6.58 0 0 1-6.7 6.4A6.58 6.58 0 0 1 6 22a6.58 6.58 0 0 1 6.7-6.4z"
          fill="#d98127"
        />
        <path
          d="M.1 36.8v-.1c0-4.8-.2-9.4 9.9-10.2v-3.9h5.5v3.9c10.2.9 9.9 5.5 9.9 10.3a23.92 23.92 0 0 1-6.9 3.1.37.37 0 0 0-.3.1 25.1 25.1 0 0 1-5.5.7 24.16 24.16 0 0 1-4.7-.5 25.22 25.22 0 0 1-7.9-3.4z"
          fill="#fdcd9a"
        />
        <path
          d="M10 23.6a19.18 19.18 0 0 1 5.5 1.9v-2.8H10v.9z"
          fill="#c79462"
        />
        <path
          d="M4.9 12.5c-.9 0-.8 5.5.8 5.5 1.3 2.9 3.6 5.3 7 5.3s5.7-2.4 7-5.3c1.5 0 1.8-5.5.8-5.5.3-6-2.2-8.5-7.7-8.5s-8.3 2.3-7.9 8.5z"
          fill="#fdcd9a"
        />
        <path
          d="M9.4 22.5c1.9.5 4.8 1.1 7-1.7 2.4-3 1.6-9 .6-13.3a7.91 7.91 0 0 0-1.5-3.2c3.6.8 5.2 3.3 5 8.2 1 0 .7 5.5-.8 5.5-1.3 2.9-3.7 5.3-7 5.3a6.22 6.22 0 0 1-3.3-.8z"
          fill="#e0b082"
        />
        <path
          d="M11.4 13.2a3.59 3.59 0 0 1 1.1-.2 3.59 3.59 0 0 1 1.1.2c1-1.2 3.4-.8 4.6-.2v.9c-.2 0-.3.1-.4.3a2.31 2.31 0 1 1-4.5-.4c-.2 0-.5-.1-.8-.1a2.2 2.2 0 0 0-.8.1 2.21 2.21 0 0 1-2.2 3 2.27 2.27 0 0 1-2.3-2.6 1.38 1.38 0 0 0-.4-.3V13c1.2-.6 3.6-1 4.6.2zM9.5 13a1.61 1.61 0 1 0 0 3.2 1.61 1.61 0 1 0 0-3.2zm5.9 0a1.61 1.61 0 1 0 0 3.2 1.6 1.6 0 1 0 0-3.2z"
          fill="#2e3852"
        />
        <path
          d="M10.3 2.4c12.8-3.9 12.3 11.9 10 16.9-.7-8.1-7.2-6.7-9.5-11.2-1.4 4.4-5.6 4.7-5.3 11.7-3.7-3.2-3.9-18.4 4.8-17.4z"
          fill="#d98127"
        />
        <path
          d="M15.5 26.5c10.2.9 9.9 5.5 9.9 10.3a23.92 23.92 0 0 1-6.9 3.1.37.37 0 0 0-.3.1 30.74 30.74 0 0 1-5.3.7h-1.8a29.09 29.09 0 0 1-3.1-.4A22.59 22.59 0 0 1 0 37v-.1c0-4.8-.2-9.4 9.9-10.2h5.6z"
          fill="#abcaeb"
        />
        <path
          d="M10.8 26.8c.4.9 1.2.8 2.2 1.6 3.3 2.6 7.3 5.7 7.2 11a19.49 19.49 0 0 0 5.2-2.6c0-4.8.3-9.5-9.9-10.3a8.34 8.34 0 0 1-4.7.3z"
          fill="#78a6d6"
        />
        <path
          d="M11.1 40.6l1-11.4-.8-1.7 1.4-1 1.5 1-.9 1.7 1 11.4c-.5 0-1 .1-1.5.1h-1.4l-.3-.1z"
          fill="#2e3852"
        />
        <path
          d="M15.7 29.6l-3-2.6-3 2.6-.7-3c.3 0 .6-.1.9-.1h5.5c.4 0 .7.1 1.1.1l-.8 3z"
          fill="#fff"
        />
      </svg>
    );
  },
  laura: props => {
    return (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.91 44">
        <path
          d="M18 39.5v-1c0-5.4-.3-10.6 11.3-11.6v-4.4h6.3v4.4c12 1 11.2 6.6 11.3 12.3v.3a26.43 26.43 0 0 1-28.9 0z"
          fill="#fdcd9a"
        />
        <path
          d="M29.3 22.4v.1c1.4.9 3.1 1.4 3.1 5.1v16.1a25.83 25.83 0 0 0 14.3-4.3v-2.2c0-5-.5-9.6-11.3-10.5v-4.4h-6.1z"
          fill="#c79462"
        />
        <path
          d="M41.3 11.1c1.1-.1.9 6.2-.9 6.3-1.5 3.2-4.1 6-7.9 6s-6.5-2.8-8-6c-1.7 0-2-6.4-.9-6.3C23.2 4.2 23.4 0 32.4 0s9.3 4 8.9 11.1z"
          fill="#fdcd9a"
        />
        <path
          d="M28.7 22.3a6.68 6.68 0 0 0 8-2.1c2.7-3.4 2.3-10.6.6-15.3A10 10 0 0 0 34.1 0c7.3.5 7.6 4.4 7.2 11 1.1-.1.9 6.2-.9 6.3a11 11 0 0 1-3.8 4.7 7.46 7.46 0 0 1-4.2 1.3 6.63 6.63 0 0 1-3.7-1z"
          fill="#e0b082"
        />
        <path
          d="M18 39.5v-1c0-5.4-.3-10.6 11.3-11.6h6.3c12 1 11.2 6.6 11.3 12.3v.3a26.43 26.43 0 0 1-28.9 0z"
          fill="#dfdfde"
        />
        <path
          fill="#74a2d2"
          d="M34.4 43.4L33.2 30l.8-1.5-1.6-1.1-1.6 1.1.9 1.5-1.2 13.1 1.9.7 2-.4z"
        />
        <path fill="#fff" d="M29.3 26.8h6.2l-.6 3-2.5-2.4-2.5 2.3-.6-2.9z" />
        <path
          d="M18 39.5v-1c0-5.4-.3-10.6 11.3-11.6L32.4 42l3.1-15.2c12 1 11.2 6.6 11.3 12.3v.3a26.4 26.4 0 0 1-14.4 4.3A25.5 25.5 0 0 1 18 39.5z"
          fill="#346090"
        />
        <path
          d="M34.7 31c3.6 2.5 6.2 6.1 6 11.4h.7c.1 0 .3-.1.4-.1h.5l.2-.1h.7l.2-.1h1.7l.2-.1.2-.1h.1l.1-.1.1-.1h.1l.2-.1.2-.1.2-.1a.52.52 0 0 1 .4-.2v-.3c0-5.7.7-11.3-11.3-12.3l-.9 2.4z"
          fill="#174474"
        />
        <path
          d="M38.9 13.6h.7c0-1.6.3-2.6 1.7-2.5C41.8 3.5 41 0 32.4 0s-9.3 3.6-8.9 11.1c1.4-.1 1.7.9 1.7 2.5h.7c0-3.9-1.2-5.7 2.8-8 7.8.8 10.2 1.1 10.2 8zM13.2 2.2c8.3 0 10.6 8.7 10.6 18.4s-4.7 12.1-10.6 12.1S2.6 30.3 2.6 20.6 4.9 2.2 13.2 2.2z"
          fill="#010101"
        />
        <path
          d="M0 40.1v-.2c0-5-.2-9.7 10.3-10.6v-4H16v4c10.6.9 10.3 5.8 10.3 10.8A23.81 23.81 0 0 1 13.1 44 23.72 23.72 0 0 1 0 40.1z"
          fill="#fdcd9a"
        />
        <path
          d="M10.3 26.3a16.85 16.85 0 0 1 5.7 2v-3h-5.7v1z"
          fill="#c79462"
        />
        <path
          d="M5 14.8c-1 0-.8 5.7.8 5.8 1.3 3 3.8 5.5 7.3 5.5s6-2.5 7.3-5.5c1.5 0 1.8-5.8.8-5.8.3-6.3-2.3-8.8-8.1-8.8C7.5 6 4.6 8.3 5 14.8z"
          fill="#fdcd9a"
        />
        <path
          d="M9.7 25.2c2 .5 5 1.1 7.3-1.7 2.5-3.1 1.7-9.4.6-13.8A7.29 7.29 0 0 0 16 6.4c3.7.8 5.4 3.5 5.2 8.6 1 0 .7 5.8-.8 5.8-1.4 3-3.8 5.5-7.3 5.5a9.71 9.71 0 0 1-3.4-1.1z"
          fill="#e0b082"
        />
        <path
          d="M0 40.1v-1c0-5-.2-9.7 10.3-10.6l2.9 13.3L16 28.5c11 .9 10.3 6.1 10.3 11.3v.3A23.64 23.64 0 0 1 13.2 44 23.73 23.73 0 0 1 0 40.1z"
          fill="#d43b27"
        />
        <path
          d="M15.2 32.4l.8-3.8c11 .9 10.3 6.1 10.3 11.3v.3c-.1.1-.2.1-.3.2l-.2.1-.2.1-.2.1H25l-.2.3-.2.1H23l-.2.1h-.7l-.2.1h-.5c-.1 0-.2.1-.4.1h-.7c.6-3.4-1.8-6.7-5.1-9z"
          fill="#ac1f23"
        />
        <path
          d="M19.7 15.3l-2 .2-1.2-2 .6 2.1-2 .1-.7-2 .2 2h-3l.2-2-.7 2-2-.1.6-2.1-1.2 2c-.5-.1-1.5-.1-2-.2-2.2-6.1.7-9.8 6.7-9.8 5.6 0 9.3 4 6.5 9.8z"
          fill="#010101"
        />
        <path
          d="M10.4 41C-.7 39.2-2.8 2.2 13.2 2.2s13.9 37 2.7 38.8C18.8 34.2 21 16.6 18 9.1H8.4c-3 7.5-.9 25.2 2 31.9z"
          fill="#010101"
        />
      </svg>
    );
  },
  joe: props => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 44.7 44.29"
      >
        <path
          d="M17.1 40.19v-1c0-5.2-.2-10.2 10.8-11.1v-4.2h6v4.2c11.5 1 10.7 6.3 10.8 11.8v.3a25 25 0 0 1-13.7 4.1 26.89 26.89 0 0 1-13.9-4.1z"
          fill="#fdcd9a"
        />
        <path
          d="M27.8 23.79v.1c1.3.9 2.9 1.4 3 4.8V44a24.73 24.73 0 0 0 13.7-4.1V38.1c0-4.8-.5-9.2-10.8-10v-4.2h-5.9z"
          fill="#c79462"
        />
        <path
          d="M39.3 13c1-.1.9 5.9-.9 6-1.4 3.1-3.9 5.7-7.6 5.7s-6.2-2.6-7.6-5.7c-1.6 0-1.9-6.1-.8-6-.4-6.5-.1-10.6 8.4-10.6s8.9 3.79 8.5 10.6z"
          fill="#fdcd9a"
        />
        <path
          d="M27.3 23.69c2.1.8 5.2 1.2 7.7-1.8 2.7-3.4 2.4-10 .8-14.5a10.49 10.49 0 0 0-3.3-5c7 .5 7.3 4.2 6.9 10.6 1-.1.9 5.9-.9 6a10 10 0 0 1-3.6 4.5 6.76 6.76 0 0 1-4 1.2 6.09 6.09 0 0 1-3.6-1z"
          fill="#e0b082"
        />
        <path d="M23.5 15.49c0-1.5-.4-2.5-1.8-2.5-1.1-5.2-.6-8.8 3.8-10.9C28.8.49 30.7 3 34 .19c1.7-1.4 9 5.3 5.2 12.8-1.4-.1-1.7.9-1.7 2.5-.4-3-.2-5.9-1.5-7.5-.7-.9-2.2 1.4-5.2 1.3-1.8-.1-3.2-2-4.2-1.4-2.2 1.5-2.7 4.5-3.1 7.6z" />
        <path
          d="M17.1 40.19v-1c0-5.2-.2-10.2 10.8-11.1a4.81 4.81 0 0 0 6 0c11.5 1 10.7 6.3 10.8 11.8v.3a25 25 0 0 1-13.7 4.1 26.89 26.89 0 0 1-13.9-4.1z"
          fill="#010101"
        />
        <path
          d="M0 40.09C.1 34.59-1 29 10.8 28v-4.5h5.9V28c11.7 1 10.7 6.6 10.8 12.1-.4.2-.8.5-1.2.7l-12.5-.1h-.1l-12.5.2c-.4-.31-.8-.51-1.2-.81z"
          fill="#f4c397"
        />
        <path
          d="M16.8 23.79h-6v.3c1.8 1.2 2.9 1.4 3 4.9v2.3h11.9l-.2-.2-.2-.2-.1-.1a.1.1 0 0 1-.1-.1l-.2-.2-.2-.2-.2-.2c-.1 0-.1-.1-.2-.1a.37.37 0 0 0-.3-.1c-.1 0-.2-.1-.3-.1-.1 0-.2-.1-.3-.1-.1 0-.2-.1-.3-.1-.1 0-.2-.1-.3-.1a2.07 2.07 0 0 1-.6-.2h-.1c-.1 0-.2-.1-.4-.1-.1 0-.2-.1-.4-.1-.1 0-.3-.1-.4-.1-.1 0-.3-.1-.4-.1-.1 0-.3-.1-.4-.1-.1 0-.3-.1-.4-.1-.1 0-.3-.1-.4-.1-.2 0-.3-.1-.5-.1a.9.9 0 0 1-.5-.1.9.9 0 0 1-.5-.1.9.9 0 0 1-.5-.1h-.5V23.9z"
          fill="#c79462"
        />
        <path
          d="M5.3 13c-1-.1-.9 6 .9 6 1.4 3.1 3.9 5.7 7.6 5.7s6.2-2.6 7.6-5.7c1.6 0 1.9-6.1.8-6 .4-6.5.1-10.6-8.4-10.6S4.9 6.19 5.3 13z"
          fill="#f4c397"
        />
        <path
          d="M0 40.09C.1 34.59-1 29 10.8 28v-1.2a13.86 13.86 0 0 0 3 .3 13.86 13.86 0 0 0 3-.3V28c11.7 1 10.7 6.6 10.8 12.1-.4.2-.8.5-1.2.7H1.2a7.46 7.46 0 0 1-1.2-.71z"
          fill="#faf1d3"
        />
        <path
          d="M10.1 23.69c2.1.6 5.2 1.2 7.7-1.8 2.6-3.2 1.8-9.8.7-14.4a8.13 8.13 0 0 0-3.2-5c7.1.5 7.3 4.4 6.9 10.6 1-.1.7 6-.8 6-1.4 3.1-4 5.7-7.6 5.7a8.89 8.89 0 0 1-3.7-1.1z"
          fill="#e0b082"
        />
        <path
          d="M20.1 13.39v2.9h.7c0-1 .1-1.8.7-2.2a1.45 1.45 0 0 0 .7.2A1.32 1.32 0 0 0 23.5 13a1.23 1.23 0 0 0-.7-1.1 1.4 1.4 0 0 0 .9-1.2 1.52 1.52 0 0 0-.7-1.2 1.39 1.39 0 0 0 .7-1.2 1.2 1.2 0 0 0-.9-1.2 1.22 1.22 0 0 0 .3-.9 1.3 1.3 0 0 0-1.2-1.3.9.9 0 0 0 .1-.5 1.32 1.32 0 0 0-1.3-1.3 1.27 1.27 0 0 0-.6.1 1.32 1.32 0 0 0-1.3-1.3 1.85 1.85 0 0 0-.7.2 1.4 1.4 0 0 0-1.2-.9 1.78 1.78 0 0 0-1 .4 1.39 1.39 0 0 0-1.2-.7 1.23 1.23 0 0 0-1 .5 1.23 1.23 0 0 0-1-.5 1.39 1.39 0 0 0-1.2.7 1.16 1.16 0 0 0-1-.4 1.29 1.29 0 0 0-1.2.9 1.45 1.45 0 0 0-.7-.2 1.32 1.32 0 0 0-1.3 1.3.75.75 0 0 0-.5-.1 1.26 1.26 0 0 0-1.3 1.21v.08a.75.75 0 0 0 .1.5 1.24 1.24 0 0 0-1.2 1.28 1.14 1.14 0 0 0 .3.8v.1a1.3 1.3 0 0 0-.8 1.2 1.15 1.15 0 0 0 .6 1.1v.1a1.15 1.15 0 0 0-.6 1.1 1.3 1.3 0 0 0 .8 1.2 1.15 1.15 0 0 0-.6 1.1 1.32 1.32 0 0 0 1.3 1.3 1.85 1.85 0 0 0 .7-.2 2.64 2.64 0 0 1 .7 2.2h.7v-2.9a1.16 1.16 0 0 0 .4-1 1.09 1.09 0 0 0-.4-.9 1.15 1.15 0 0 0 .6-1.1 1.07 1.07 0 0 0-.2-.6h.2a1.38 1.38 0 0 0 1.3-1 1.16 1.16 0 0 0 1 .4 1.39 1.39 0 0 0 1.2-.7 1.39 1.39 0 0 0 1.2.7 1.23 1.23 0 0 0 1-.5 1.23 1.23 0 0 0 1 .5 1.39 1.39 0 0 0 1.2-.7 1.39 1.39 0 0 0 1.2.7 1.78 1.78 0 0 0 1-.4 1.52 1.52 0 0 0 1.3 1h.2a1.42 1.42 0 0 0-.2.6 1.36 1.36 0 0 0 .6 1.1 1.27 1.27 0 0 0-.4.9 1 1 0 0 0 .4 1.12z"
          fill="#291e0f"
        />
        <path
          d="M0 40.09C.1 34.59-1 29 10.8 28l3 10.9 3-10.9c11.7 1 10.7 6.6 10.8 12.1a24.81 24.81 0 0 1-13.7 4.1A25.23 25.23 0 0 1 0 40.09z"
          fill="#6b3c17"
        />
        <path
          d="M15.7 31.89c3.5 2.4 6.1 5.9 6 11a25.84 25.84 0 0 0 4.8-2.1l.1-.1h.2l.1-.1.1-.1c-.1-5.5 1-11.1-10.8-12.1z"
          fill="#52240f"
        />
        <path
          d="M35.8 13.89l-4.1.1a.85.85 0 0 0-.7.8v.1a.64.64 0 0 0-.6 0v-.1a.77.77 0 0 0-.7-.8l-4.1-.1a.77.77 0 0 0-.7.8l.1 1.9a.77.77 0 0 0 .7.8h3.9a.77.77 0 0 0 .7-.8l.1-1.3a.85.85 0 0 1 .7 0l.1 1.3c.1.4.3.8.7.8h3.9a.77.77 0 0 0 .7-.8v-1.9a.77.77 0 0 0-.7-.8zm-5.9 2.7c0 .2-.1.4-.3.4h-3.9c-.2 0-.3-.2-.3-.4l-.1-2a.27.27 0 0 1 .24-.3h.06l4.2.2a.32.32 0 0 1 .3.3zm6.2 0c0 .2-.1.4-.3.4h-3.9c-.2 0-.3-.2-.3-.4l-.1-1.8a.32.32 0 0 1 .3-.3l4.1-.1h.1a.27.27 0 0 1 .24.3z"
          fill="#010101"
        />
      </svg>
    );
  }
};

const GuiIcons = {
  hoh: props => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 42.4 48.06"
      >
        <path fill="#eac3a2" d="M18.7 20.66h10.2v15.5H18.7z" />
        <path
          d="M33.1 9.76v7.3c0 5.5-4.2 10-9.3 10s-9.3-4.5-9.3-10v-7.3z"
          fill="#f1d9c5"
        />
        <path
          d="M14.4 9.76a8.21 8.21 0 0 1 4.8-7.3l6.3-2.4a1 1 0 0 1 1.2.5l1.3 2.8a5.35 5.35 0 0 1 5.1 5.5v5.5a4.45 4.45 0 0 0-4.2-4.6H18.7a4.39 4.39 0 0 0-4.2 4.6v-4.6z"
          fill="#c6a279"
        />
        <path
          d="M42.4 38.96a4.46 4.46 0 0 0-2.3-3.8l-11.2-5.3-5.1 5.5-5.1-5.5-11.2 5.2a4.46 4.46 0 0 0-2.3 3.8v8.1a.81.81 0 0 0 .8 1h35.5a.82.82 0 0 0 .8-.9v-8.1z"
          fill="#444"
        />
        <path
          d="M4.2 44.66v-15.7a7.59 7.59 0 0 1 7.8-7.3 7.52 7.52 0 0 1 7.8 7.3v15.6H4.2z"
          fill="#e2c650"
        />
        <path fill="#e5cebe" d="M8.4 34.56h7.2v8.4H8.4z" />
        <path
          d="M22 43.16l-6.4-3a6.15 6.15 0 0 1-3.6 1.1 6.15 6.15 0 0 1-3.6-1.1l-6.4 3a3.38 3.38 0 0 0-2 3v1.3a.65.65 0 0 0 .6.6h22.7a.58.58 0 0 0 .6-.6v-1.3a3 3 0 0 0-1.9-3z"
          fill="#e86c60"
        />
        <path
          d="M5.4 30.66v1.1a6.37 6.37 0 0 0 6.6 6.1 6.44 6.44 0 0 0 6.6-6.1v-1.1a1.77 1.77 0 0 0-1.8-1.7 5.46 5.46 0 0 1-4.8-2.7 5.46 5.46 0 0 1-4.8 2.7 1.77 1.77 0 0 0-1.8 1.7z"
          fill="#efdacb"
        />
        <path
          d="M6.9 40.86l1.2 2.5c.2.3.6.5.9.2l3-2.3a6.38 6.38 0 0 1-3.6-1.1zM15.6 40.16a6.15 6.15 0 0 1-3.6 1.1l3 2.3a.63.63 0 0 0 .9-.2l1.2-2.5z"
          fill="#fff"
        />
      </svg>
    );
  },
  itemize: props => {
    return (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 36.5">
        <path
          d="M13.5 30.1H.8a.79.79 0 0 0-.8.8v2.4c0 2 3.6 3.1 7.1 3.1s7.1-1.1 7.1-3.2v-2.4a.68.68 0 0 0-.7-.7z"
          fill="#d8bc48"
        />
        <path
          d="M7.1 27.7c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.5-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M13.5 26.1H.8a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.1 7.1 3.1s7.1-1.1 7.1-3.2v-2.4a.75.75 0 0 0-.7-.7z"
          fill="#d8bc48"
        />
        <path
          d="M7.1 23.7c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.5-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M13.5 22.2H.8a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2V23a.71.71 0 0 0-.7-.8z"
          fill="#d8bc48"
        />
        <path
          d="M7.1 19.8C3.6 19.8 0 20.9 0 23s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.5-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M13.5 18.2H.8a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2V19a.77.77 0 0 0-.7-.8z"
          fill="#d8bc48"
        />
        <path
          d="M7.1 15.8C3.6 15.8 0 16.9 0 19s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.5-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M47.2 30.1H34.5a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2v-2.4a.67.67 0 0 0-.7-.8z"
          fill="#d8bc48"
        />
        <path
          d="M40.9 27.7c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2S48 33 48 30.9s-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M47.2 26.1H34.5a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.2-1.1 7.2-3.2v-2.4a.86.86 0 0 0-.8-.8z"
          fill="#d8bc48"
        />
        <path
          d="M40.9 23.7c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2S48 29 48 26.9s-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M47.2 22.2H34.5a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.2-1.2 7.2-3.3v-2.4a.77.77 0 0 0-.8-.7z"
          fill="#d8bc48"
        />
        <path
          d="M40.9 19.8c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2S48 25.1 48 23s-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M47.2 18.2H34.5a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.2-1.2 7.2-3.2V19a.86.86 0 0 0-.8-.8z"
          fill="#d8bc48"
        />
        <path
          d="M40.9 15.8c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2S48 21.1 48 19s-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M47.2 14.2H34.5a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.2-1.1 7.2-3.2V15a.86.86 0 0 0-.8-.8z"
          fill="#d8bc48"
        />
        <path
          d="M40.9 11.9c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M47.2 10.3H34.5a.86.86 0 0 0-.8.8v2.3c0 2.1 3.6 3.2 7.1 3.2s7.2-1.1 7.2-3.2V11a.77.77 0 0 0-.8-.7z"
          fill="#d8bc48"
        />
        <path
          d="M40.9 7.9c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M30.3 30.1H17.7a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2v-2.4a.74.74 0 0 0-.8-.8z"
          fill="#d8bc48"
        />
        <path
          d="M24 27.7c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M30.3 26.1H17.7a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2v-2.4a.79.79 0 0 0-.8-.8z"
          fill="#d8bc48"
        />
        <path
          d="M24 23.7c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M30.3 22.2H17.7a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2V23a.74.74 0 0 0-.8-.8z"
          fill="#d8bc48"
        />
        <path
          d="M24 19.8c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M30.3 18.2H17.7a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2V19a.79.79 0 0 0-.8-.8z"
          fill="#d8bc48"
        />
        <path
          d="M24 15.8c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M30.3 14.2H17.7a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2V15a.79.79 0 0 0-.8-.8z"
          fill="#d8bc48"
        />
        <path
          d="M24 11.9c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M30.3 10.3H17.7a.86.86 0 0 0-.8.8v2.3c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2V11a.71.71 0 0 0-.8-.7z"
          fill="#d8bc48"
        />
        <path
          d="M24 7.9c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M30.3 6.3H17.7a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2V7.1a.79.79 0 0 0-.8-.8z"
          fill="#d8bc48"
        />
        <path
          d="M24 3.9c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2-3.6-3.2-7.1-3.2z"
          fill="#efd358"
        />
        <path
          d="M30.3 2.4H17.7a.86.86 0 0 0-.8.8v2.4c0 2.1 3.6 3.2 7.1 3.2s7.1-1.1 7.1-3.2V3.2a.74.74 0 0 0-.8-.8z"
          fill="#d8bc48"
        />
        <path
          d="M24 0c-3.5 0-7.1 1.1-7.1 3.2s3.6 3.2 7.1 3.2 7.1-1.1 7.1-3.2S27.5 0 24 0z"
          fill="#efd358"
        />
      </svg>
    );
  },
  married: props => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.01 46.76"
      >
        <path
          d="M27.85 14.6a16.31 16.31 0 1 0 19.7 12 16.34 16.34 0 0 0-19.7-12zm7 29.1a13.58 13.58 0 1 1 10-16.4 13.56 13.56 0 0 1-10 16.4z"
          fill="#cea229"
        />
        <path
          d="M12.45 7.4a16.31 16.31 0 1 0 19.7 12 16.34 16.34 0 0 0-19.7-12zm7 29.1a13.58 13.58 0 1 1 10-16.4 13.56 13.56 0 0 1-10 16.4z"
          fill="#e2bc3a"
        />
        <path
          d="M16.25 2L14.35.2a.84.84 0 0 0-.7-.2l-5.6 1.4a.68.68 0 0 0-.6.4l-.9 2.5v.4l9.9-2.4c0-.1-.1-.2-.2-.3z"
          fill="#9bced3"
        />
        <path
          d="M6.55 4.7c0 .2.1.3.3.4l5.4 3.9a.77.77 0 0 0 1.1-.3l3-6a.9.9 0 0 0 .1-.5z"
          fill="#76b5b5"
        />
      </svg>
    );
  },
  plus: props => {
    return (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 36.2">
        <path
          d="M17.5 36.2C7.85 36.2 0 28.1 0 18.1S7.85 0 17.5 0 35 8.1 35 18.1s-7.85 18.1-17.5 18.1z"
          fill="#72c472"
        />
        <path
          fill="#fff"
          d="M26.63 15.74h-6.85V8.66h-4.56v7.08H8.37v4.72h6.85v7.08h4.56v-7.08h6.85v-4.72z"
        />
      </svg>
    );
  },
  single: props => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31.06 47.8"
      >
        <path
          fill="#010101"
          fillRule="evenodd"
          d="M15.56 14.17c6.11 0 11.06 6.95 11.06 15.52a21.26 21.26 0 0 1-.54 4.78h-21a21.3 21.3 0 0 1-.54-4.78c0-8.57 5-15.52 11.06-15.52"
        />
        <path
          fill="#99663c"
          fillRule="evenodd"
          d="M.26 43.28v-1.1c0-5.77-.27-11.3 11.95-12.33v-4.69h6.65v4.69c12.74 1.08 11.91 7 12 13.08v.35a28 28 0 0 1-30.55 0"
        />
        <path
          fill="#723f19"
          fillRule="evenodd"
          d="M12.2 26.31a25.07 25.07 0 0 1 6.65 2.2v-3.36H12.2z"
        />
        <path
          fill="#99663c"
          fillRule="evenodd"
          d="M24.95 13.1c1.13-.12 1 6.61-1 6.65-1.57 3.44-4.37 6.36-8.43 6.36s-6.9-2.92-8.47-6.36c-1.75 0-2.09-6.77-.94-6.65-.33-7.25-.12-11.78 9.37-11.78s9.93 4.27 9.47 11.78"
        />
        <path
          fill="#845023"
          fillRule="evenodd"
          d="M11.56 25.05c2.37.93 5.43 1.27 8.11-2.07 2.86-3.57 2.7-11.57.92-16.61a10 10 0 0 0-3.3-5c7.74.54 8.08 4.72 7.65 11.72 1.13-.12 1 6.61-1 6.65a11.88 11.88 0 0 1-4 5 7.94 7.94 0 0 1-8.39.28"
        />
        <path
          fill="#010101"
          fillRule="evenodd"
          d="M15.56 0c10.22 0 13.22 10 9.7 18.26.43-1.86.22-4.24-.66-4.21l-9.13-6.22-9 6.22c-.89 0-1.08 2.11-.7 3.93C2.65 9.76 5.29 0 15.56 0"
        />
        <path
          fill="#673872"
          fillRule="evenodd"
          d="M0 43v-.23c0-4.73-.16-9.3 5.59-11.61A17.51 17.51 0 0 0 15.53 34a17.51 17.51 0 0 0 9.94-2.83c5.83 2.35 5.59 7 5.59 11.8A27.67 27.67 0 0 1 0 43"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28.28 32.87c-6.46 5.72-19 5.72-25.5 0a11.74 11.74 0 0 1 5-2.35 16.46 16.46 0 0 0 15.55 0 11.76 11.76 0 0 1 5 2.35z"
        />
      </svg>
    );
  },
  standardDeduction: props => {
    return (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.76 48">
        <path
          d="M34 34.4l-9.3-20.8 4.1-12.2a.94.94 0 0 0-.1-.9 1.05 1.05 0 0 0-.9-.5H7a1 1 0 0 0-.8.4 1 1 0 0 0-.1.9l4.1 12.2L.8 34.4a9.71 9.71 0 0 0 .8 9.2A9.93 9.93 0 0 0 9.8 48h15.1A9.85 9.85 0 0 0 34 34.4z"
          fill="#a67c52"
        />
        <path
          d="M17.4 14.6a1 1 0 0 1-1-1V7.3a1 1 0 1 1 2 0v6.3a1 1 0 0 1-1 1z"
          fill="#87613e"
        />
        <path
          d="M17.7 30.3c-1.9-.5-2.4-.7-2.3-1.7a1.48 1.48 0 0 1 1.6-1.4h1a1.64 1.64 0 0 1 1.6 1.6 1 1 0 0 0 2 0 3.74 3.74 0 0 0-3.1-3.6V23a1 1 0 0 0-1-1 1 1 0 0 0-1 1v2.1a3.65 3.65 0 0 0-3.1 3.6c0 2.6 2.4 3.2 3.9 3.6 1.9.5 2.4.7 2.3 1.7a1.48 1.48 0 0 1-1.6 1.4h-1a1.64 1.64 0 0 1-1.6-1.6 1 1 0 0 0-2 0 3.74 3.74 0 0 0 3.1 3.6v2.1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-2.1a3.65 3.65 0 0 0 3.1-3.6c0-2.5-2.4-3.1-3.9-3.5zM24.7 14.6H10.1a1 1 0 1 1 0-2h14.6a1 1 0 1 1 0 2z"
          fill="#efd358"
        />
      </svg>
    );
  }
};

const SocialIcon = styled.a`
  align-items: center;
  background-color: ${props => (props.fill ? props.fill : '#999999')};
  border: 1px solid ${props => (props.fill ? props.fill : '#999999')};
  border-radius: 4px;
  color: ${props => (props.color ? props.color : '#ffffff')};
  display: block;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-decoration: none;

  svg {
    max-height: ${props => (props.height ? props.height : '48px')};
    max-width: ${props => (props.width ? props.width : '48px')};
  }
`;

const ShareIcons = {
  IconTwitter: props => {
    return (
      <SocialIcon
        fill={props.fill}
        href={
          'https://twitter.com/intent/tweet?' +
          'text=' +
          encodeURI(props.message) +
          (props.hashtags ? '&hashtags=' + props.hashtags : '') +
          (props.noVia ? '' : '&via=taxfoundation') +
          '&url=' +
          encodeURI(window.location.href)
        }
        target="_blank"
        height={props.height}
        width={props.width}
      >
        <svg id="twitter" viewBox="0 0 48 48">
          <path
            fill={props.color}
            d="M48,9.11341c-1.76603,0.78322-3.66389,1.31268-5.65607,1.55067 c2.03332-1.21873,3.5948-3.14867,4.33001-5.44828c-1.90268,1.12855-4.01024,1.94811-6.25344,2.3898 c-1.79636-1.914-4.35574-3.10992-7.18805-3.10992c-5.43885,0-9.84807,4.40923-9.84807,9.84756 c0,0.77191,0.0871,1.5234,0.25495,2.24422c-8.1844-0.41065-15.4407-4.33121-20.29778-10.28923 C2.49387,7.75272,2.0083,9.44432,2.0083,11.24909c0,3.41649,1.73858,6.43073,4.38093,8.19676 c-1.61427-0.05109-3.13272-0.49415-4.4605-1.23177c-0.00069,0.04115-0.00084,0.08231-0.00084,0.1238 c0,4.77144,3.39452,8.75168,7.8996,9.6563c-0.82642,0.22494-1.69641,0.34532-2.5945,0.34532 c-0.63458,0-1.25149-0.06173-1.8528-0.17661c1.25319,3.91234,4.89001,6.75958,9.19929,6.83914 c-3.37036,2.64116-7.61654,4.21549-12.23032,4.21549C1.55427,39.21751,0.77036,39.17088,0,39.08 c4.35814,2.79408,9.53447,4.42431,15.09573,4.42431c18.11374,0,28.0189-15.00571,28.0189-28.01916 c0-0.42694-0.00959-0.85164-0.02846-1.27394C45.01011,12.82274,46.67978,11.08826,48,9.11341z"
          />
        </svg>
        {props.text ? <span>{props.text}</span> : null}
      </SocialIcon>
    );
  },
  IconFacebook: props => {
    return (
      <SocialIcon
        fill={props.fill}
        href={
          'https://www.facebook.com/dialog/share?app_id=1667818153232107&display=page&href=' +
          encodeURI(window.location.href) +
          '&redirect_uri=' +
          encodeURI(window.location.href)
        }
        target="_blank"
        height={props.height}
        width={props.width}
      >
        <svg id="facebook" viewBox="0 0 48 48">
          <path
            fill={props.color}
            d="M18.06306,46L18,26h-8v-8h8v-5c0-7.42279,4.59664-11,11.21828-11 c3.17183,0,5.89786,0.23615,6.6923,0.3417v7.75726l-4.59246,0.00209c-3.60122,0-4.2985,1.71125-4.2985,4.22238V18H37.5l-4,8 h-6.48038v20H18.06306z"
          />
        </svg>
        {props.text ? <span>{props.text}</span> : null}
      </SocialIcon>
    );
  },
  IconLinkedIn: props => {
    return (
      <SocialIcon
        fill={props.fill}
        href={
          'https://www.linkedin.com/shareArticle?mini=true&url=' +
          encodeURI(window.location.href) +
          (props.message ? '&summary=' + encodeURI(props.message) : '')
        }
        target="_blank"
        height={props.height}
        width={props.width}
      >
        <svg id="linkedin" viewBox="0 0 48 48">
          <path
            fill={props.color}
            d="M10.74,48H1V16h9.74V48z M5.75,11.5C2.56,11.5,0,8.94,0,5.75C0,2.56,2.56,0,5.75,0
            c3.18,0,5.75,2.56,5.75,5.75C11.5,8.94,8.93,11.5,5.75,11.5z M48,48H38V32.41c0-3.72,0.1-8.41-5-8.41c-5.18,0-6,3.82-6,8v16H16.98
            V16h9.54v4.32h0.13C27.98,17.8,31.16,15,36,15c10.07,0,12,6.77,12,15.4V48z"
          />
        </svg>
        {props.text ? <span>{props.text}</span> : null}
      </SocialIcon>
    );
  },
  IconEmail: props => {
    return (
      <SocialIcon
        fill={props.fill}
        href={`mailto:?subject=${props.emailSubject}&body=${props.emailBody}`}
        height={props.height}
        width={props.width}
      >
        <svg id="email" viewBox="0 0 64 64">
          <path
            fill={props.color}
            d="M3.5,25l12.1,8.8l16.8-9.1c0.5-0.3,1,0.4,0.6,0.8L19,37v19.4c0,0.9,1.1,1.4,1.7,0.7L31,45.5L49.5,59c0.6,0.4,1.4,0.1,1.6-0.6l10-54c0.1-0.8-0.6-1.4-1.3-1.1l-56,20C3,23.6,2.9,24.6,3.5,25z"
          />
        </svg>
        {props.text ? <span>{props.text}</span> : null}
      </SocialIcon>
    );
  }
};

export { ProfileIcons, GuiIcons, ShareIcons };
