import styled from 'styled-components';

const GraphicButton = styled.div`
  align-items: center;
  background-color: ${props =>
    props.selected ? props.theme.tfBlueHover : '#fff'};
  border: 1px solid ${props => (props.selected ? props.theme.tfBlue : '#999')};
  cursor: pointer;
  display: grid;
  grid-gap: 1rem;
  grid-template: auto / 1fr 3fr;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: ${props => props.theme.tfBlueHover};
    border: 1px solid ${props => props.theme.tfBlue};
  }

  @media (max-width: 500px) {
    padding: 0.6rem;
  }

  svg {
    max-height: 80px;
    max-width: 100%;
  }
`;

export default GraphicButton;
