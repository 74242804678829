import React, { Component, Fragment } from 'react';
import Button from './Button';
import SimpleContainer from './SimpleContainer';
import NumericInput from './NumericInput';
import CheckboxInput from './CheckboxInput';

class IncomeInputs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdditionalInputs: false,
    };

    this.updateInput = this.updateShowMore.bind(this);
  }

  componentDidMount() {
    this.props.scroll(this.refs.income);
  }

  updateShowMore() {
    let moarInputs = !this.state.showAdditionalInputs;
    this.setState({ showAdditionalInputs: moarInputs });
  }

  render() {
    return (
      <div ref="income">
        <SimpleContainer
          rows="1"
          columns={this.props.taxpayer.filing_status === 1 ? 2 : 1}
          gridGap="1rem"
        >
          <NumericInput
            id="ordinary_income1"
            format="shortDollar"
            name="Personal Income"
            onChange={this.props.updateInput}
            onEnter={this.props.calculate}
            placeholder={this.props.taxpayer.ordinary_income1}
          />
          {this.props.taxpayer.filing_status === 1 ? (
            <NumericInput
              id="ordinary_income2"
              format="shortDollar"
              name="Spouse's Income"
              onChange={this.props.updateInput}
              onEnter={this.props.calculate}
              placeholder={this.props.taxpayer.ordinary_income2}
            />
          ) : null}
        </SimpleContainer>
        {this.state.showAdditionalInputs ? (
          <Fragment>
            <SimpleContainer rows="1" columns="1" gridGap="1rem">
              <NumericInput
                id="401k_contributions"
                format="shortDollar"
                name="Pre-tax 401(k) Contributions"
                onChange={this.props.updateInput}
                onEnter={this.props.calculate}
                placeholder="0"
              />
            </SimpleContainer>
            <SimpleContainer rows="1" columns="2" gridGap="1rem">
              <NumericInput
                id="ss_income"
                format="shortDollar"
                name="Social Security Benefits"
                onChange={this.props.updateInput}
                onEnter={this.props.calculate}
                placeholder={this.props.taxpayer.ss_income}
                tooltip="When adding any Social Security benefits received, this calculator will automatically assume the taxpayer qualifies for the additional standard deduction."
              />
              <NumericInput
                id="qualified_income"
                format="shortDollar"
                name="Long-term Capital Gains and/or Qualified Dividends"
                onChange={this.props.updateInput}
                onEnter={this.props.calculate}
                placeholder={this.props.taxpayer.qualified_income}
              />
            </SimpleContainer>
            <SimpleContainer rows="1" columns="2" gridGap="1rem">
              <NumericInput
                id="business_income"
                format="shortDollar"
                name="Business Income"
                onChange={this.props.updateInput}
                onEnter={this.props.calculate}
                placeholder={this.props.taxpayer.business_income}
              />
              <CheckboxInput
                id="business_income_service"
                name="Business income is from a professional services business."
                yes={1}
                no={0}
                onChange={this.props.updateInput}
                placeholder={this.props.taxpayer.business_income_service}
                tooltip="Professional service businesses include businesses in fields of the health, law, accounting, consulting, financial services, among others."
              />
            </SimpleContainer>
          </Fragment>
        ) : null}
        {this.state.showAdditionalInputs ? null : (
          <Button
            inverted
            onClick={e => {
              e.preventDefault();
              this.updateShowMore();
            }}
          >
            Show More Income Options
          </Button>
        )}
      </div>
    );
  }
}

export default IncomeInputs;
