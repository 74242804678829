import React, { Fragment } from 'react';
import styled, { keyframes } from 'styled-components';
import Tooltip from './Tooltip';
import SimpleContainer from './SimpleContainer';
import { formats } from './helpers';

const Animation = keyframes`
  from {
    opacity: 0;
    transform: scaleY(0)
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
`;

const Label = styled.label`
  font-size: 20px;
  font-weight: 700;
`;

const Input = styled.input`
  -moz-appearance: textfield;
  border: 1px solid #999;
  border-radius: 4px;
  display: block;
  font-size: ${props => props.theme.fontSize};
  margin: 0;
  padding: 4px 8px;
  text-align: right;
  width: 100%;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    border: 1px solid ${props => props.theme.tfBlue};
  }
`;

const Container = styled(SimpleContainer)`
  animation: ${Animation} 0.4s;
`;

/**
 * NumericInput generates a labeled input that can update state through props.
 * @param {Object} props - The properties used in the final component.
 */
const NumericInput = props => {
  return (
    <Fragment>
      <Container rows="2" gridGap="0.5rem">
        <Label htmlFor={`input-${props.id}`}>
          {props.tooltip ? (
            <Fragment>
              <span>{props.name}</span>
              <Tooltip
                tooltipId={`tooltip-${props.id}`}
                tooltipText={props.tooltip}
              />
            </Fragment>
          ) : (
            props.name
          )}
        </Label>
        <Input
          disabled={props.disabled}
          id={`input-${props.id}`}
          min={props.min || 0}
          type="number"
          onChange={event => props.onChange(event.target.value, props.id)}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              props.onChange(event.target.value, props.id);
              props.onEnter();
            }
          }}
          placeholder={
            props.format
              ? formats[props.format](props.placeholder)
              : props.placeholder
          }
        />
      </Container>
    </Fragment>
  );
};

export default NumericInput;
