import styled from 'styled-components';

const Button = styled.button`
  background-color: ${props => (props.inverted ? '#fff' : props.theme.tfBlue)};
  border: 1px solid
    ${props =>
      props.active ? props.theme.tfBlueHighlight : props.theme.tfBlue};
  border-radius: 4px;
  color: ${props => (props.inverted ? props.theme.tfBlue : '#fff')};
  cursor: pointer;
  display: block;
  font-size: ${props => props.theme.fontSize};
  margin: 1rem auto 0 !important;
  padding: 10px 20px;

  &:hover {
    background-color: ${props =>
      props.inverted ? props.theme.tfBlueHover : props.theme.tfBlueHighlight};
    border: 1px solid ${props => props.theme.tfBlueHighlight};
    color: ${props => (props.inverted ? props.theme.tfBlue : '#fff')};
  }
`;

export default Button;
