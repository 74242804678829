import React from 'react';
import SimpleContainer from './SimpleContainer';
import GraphicButton from './GraphicButton';
import { GuiIcons } from '../images/Images';

const FilingStatus = props => {
  const statuses = [
    { id: 'single', digit: 0, name: 'Single Filer' },
    { id: 'married', digit: 1, name: 'Married Filing Jointly' },
  ];

  return (
    <div>
      <h2>{props.name}</h2>
      <SimpleContainer rows="1" columns="2" gridGap="1rem">
        {statuses.map(s => {
          return (
            <GraphicButton
              key={`${props.id}-${s.id}`}
              onClick={() => {
                props.update(s.digit, props.id);
                if (s.id !== 'married') {
                  props.update(0, 'ordinary_income2');
                }
              }}
              selected={s.digit === props.selected}
            >
              {GuiIcons[s.id]({ height: 36 })}
              <p>{s.name}</p>
            </GraphicButton>
          );
        })}
      </SimpleContainer>
    </div>
  );
};

export default FilingStatus;
