import React from 'react';
import styled from 'styled-components';
import { ShareIcons } from '../images/Images';

const Container = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template: 30px / repeat(4, 30px);
  justify-content: center;
  padding: 0;
`;

const SocialButtons = props => {
  return (
    <Container>
      {ShareIcons.IconTwitter({
        color: '#00b6f1',
        fill: '#fff',
        height: props.size,
        width: props.size,
        message: props.message,
        hashtags: props.hashtags,
      })}
      {ShareIcons.IconFacebook({
        color: '#3b5998',
        fill: '#fff',
        height: props.size,
        width: props.size,
      })}
      {ShareIcons.IconLinkedIn({
        color: '#007bb6',
        fill: '#fff',
        height: props.size,
        width: props.size,
        message: props.message,
      })}
      {ShareIcons.IconEmail({
        color: '#df4a32',
        fill: '#fff',
        height: props.size,
        width: props.size,
        emailSubject: props.emailSubject,
        emailBody: props.emailBody,
      })}
    </Container>
  );
};

export default SocialButtons;
