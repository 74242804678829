import React from 'react';
import { colorForValue } from './helpers';

const ColorizeNumber = props => {
  let goodOrBadSign = props.sign === '+' ? 1 : -1;
  if (props.value * goodOrBadSign > 0) {
    return (
      <span style={{ color: colorForValue(props.value, props.sign) }}>
        {props.sign === '+' ? `+${props.children}` : props.children}
      </span>
    );
  } else if (props.value * goodOrBadSign < 0) {
    return (
      <span style={{ color: colorForValue(props.value, props.sign) }}>
        {props.children}
      </span>
    );
  } else {
    return <span>{props.children}</span>;
  }
};

export default ColorizeNumber;
