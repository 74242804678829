import React, { Component } from 'react';
import config from './config.json';
import Theme from './Theme';
import styled, { ThemeProvider } from 'styled-components';
import Banner from './components/Banner';
import SocialButtons from './components/SocialButtons';
import ProfileSelect from './components/ProfileSelect';
import CustomInputs from './components/CustomInputs';
import PlanChoice from './components/PlanChoice';
import ComparisonSummary from './components/ComparisonSummary';

const AppWrapper = styled.div`
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSize};
  margin: 0 auto;
  max-width: 720px;

  @media screen and (max-width: 720px) {
    padding: 0 1rem;
  }

  * {
    box-sizing: border-box;
  }
`;

class App extends Component {
  state = {
    taxpayer: {
      business_income_service: 0,
      filing_status: undefined,
      child_dep: 0,
      nonchild_dep: 0,
      ordinary_income1: 0,
      ordinary_income2: 0,
      business_income: 0,
      ss_income: 0,
      qualified_income: 0,
      '401k_contributions': 0,
      medical_expenses: 0,
      sl_income_tax: 0,
      sl_property_tax: 0,
      interest_paid: 0,
      charity_contributions: 0,
      other_itemized: 0,
    },
    profile: undefined,
    deduction: 'standardDeduction',
    year: 2018,
    plan1: 'pre-tcja',
    plan2: 'tcja',
    upToDate: true,
  };

  onTF = (referrer) => {
    const localhost = /localhost/;
    const taxfoundation = /taxfoundation\.org/;
    return (localhost.test(referrer) || taxfoundation.test(referrer));
  }

  calculate = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    /**
     * Only count spouse income if married
     */
    if (this.state.taxpayer.filing_status !== 1) {
      this.updateInput(0, 'ordinary_income2');
    }

    /**
     * Decide whether filer is 'single' or 'head of household'
     */
    const hasDeps =
      this.state.taxpayer.child_dep > 0 || this.state.taxpayer.nonchild_dep > 0;

    if (this.state.taxpayer.filing_status === 0 && hasDeps) {
      this.updateInput(2, 'filing_status');
    } else if (this.state.taxpayer.filing_status === 2 && !hasDeps) {
      this.updateInput(0, 'filing_status');
    }

    /**
     * Zero out deductions if you switch to standard again
     */
    if (this.state.deduction === 'standardDeduction') {
      const deductions = [
        'medical_expenses',
        'sl_income_tax',
        'sl_property_tax',
        'interest_paid',
        'charity_contributions',
        'other_itemized',
      ];
      deductions.forEach(d => this.updateInput(0, d));
    }

    /**
     * Craft request to taxsim API endpoint
     */
    const request = new Request(config.apiEndpoint, {
      headers: headers,
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(this.state.taxpayer),
    });

    /**
     * Get the results and set state. We DO NOT save inputs/outputs on server.
     */
    fetch(request)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Network unable to fulfill request');
        }
      })
      .then(data => {
        if (this.state.error) this.setError(undefined);
        this.updateTaxes(data);
      })
      .catch(error => {
        this.setError(error.message);
      });
  }

  updateTaxes = (data) => {
    this.setState({ taxes: data, upToDate: true });
  }

  setError = (error) => {
    this.setState({ error: error });
  }

  updateYear = (year) => {
    const plansInYear = [...new Set(this.state.taxes.filter(t => +t.plan.year === +year).map(t => t.plan.id))];
    if (!plansInYear.includes(this.state.plan1)) {this.updatePlan('plan1', 'pre-tcja')}
    if (!plansInYear.includes(this.state.plan2)) {this.updatePlan('plan2', 'tcja')}
    this.setState({ year: +year });
  }

  updatePlan = (plan, id) => {
    this.setState({[plan]: id});
  }

  updateInput = (value, id) => {
    let newState = Object.assign({}, this.state);
    newState.taxpayer[id] = +value;
    newState.upToDate = false;
    this.setState(newState);
  }

  updateProfile = (profile) => {
    this.setState({ profile });
    if (this.state.error) this.setError(undefined);
  }

  updateDeduction = (id) => {
    this.setState({ deduction: id });
  }

  scrollToPlace = (element) => {
    let offset = element.getBoundingClientRect().top - 70;
    window.scrollBy({ top: offset, left: 0, behavior: 'smooth' });
  }

  render() {
    return (
      <ThemeProvider theme={Theme}>
        <AppWrapper id="app">
        {(window.location !== window.parent.location && this.onTF(document.referrer))
          ? null
          : <div>
              <Banner />
              <h1 style={{ textAlign: 'center' }}>
                How Will Recent Tax Plans Impact You?
              </h1>
            </div>
        }
          <SocialButtons
            size="30px"
            message="How will the Tax Cuts and Jobs Act impact after-tax incomes? This new calculator allows you to calculate a custom scenario."
            hashtags="TaxReform"
            emailSubject="Tax Reform Calculator"
            emailBody={`The Tax Foundation's tax reform calculator shows you how the Tax Cuts and Jobs Act will impact after-tax income, total taxes owed, effective tax rates, and more. You can calculate a custom scenario here: ${
              window.location.href
            }`}
          />
          <ProfileSelect
            selection={this.state.profile}
            updateProfile={this.updateProfile}
            updateTaxes={this.updateTaxes}
          />
          {this.state.profile === 'custom' ? (
            <CustomInputs
              scroll={this.scrollToPlace}
              calculate={this.calculate}
              deduction={this.state.deduction}
              taxpayer={this.state.taxpayer}
              upToDate={this.state.upToDate}
              updateDeduction={this.updateDeduction}
              updateInput={this.updateInput}
              updateTaxes={this.updateTaxes}
            />
          ) : null}
          {this.state.taxes ? (
            <div>
              <PlanChoice
                year={this.state.year}
                plan1={this.state.plan1}
                plan2={this.state.plan2}
                updateYear={this.updateYear}
                updatePlan={this.updatePlan}
                yearsAvailable={Array.from(new Set(this.state.taxes
                  .map(t => t.plan.year)
                ))}
                plansAvailable={Array.from(new Set(this.state.taxes
                  .filter(t => t.plan.year === this.state.year)
                  .map(t => t.plan.id))).map(t => {
                    return {
                      id: t,
                      name: this.state.taxes.find(n => n.plan.id === t).plan.name
                    };
                  })
                }
              />
              <ComparisonSummary
                scroll={this.scrollToPlace}
                year={this.state.year}
                plan1={this.state.taxes.find(
                  t => t.plan.year === this.state.year && t.plan.id === this.state.plan1
                )}
                plan2={this.state.taxes.find(
                  t => t.plan.year === this.state.year && t.plan.id === this.state.plan2
                )}
                resultsMeta={config.resultsMeta}
              />
            </div>
          ) : null}
          {this.state.error ? (
            <div>
              <h2>We're Sorry, Something Went Wrong</h2>
              <p>
                The calculator was unable to create an estimate. Please review
                your input and try again later.
              </p>
              <p>
                Error returned:{' '}
                <span style={{ fontFamily: 'monospace' }}>
                  {this.state.error}
                </span>
              </p>
            </div>
          ) : null}
        </AppWrapper>
      </ThemeProvider>
    );
  }
}

export default App;
