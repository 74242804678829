import React, { Component } from 'react';
import FilingStatus from './FilingStatus';
import IncomeInputs from './IncomeInputs';
import DependentsInput from './DependentsInput';
import DeductionSelect from './DeductionSelect';
import Button from './Button';

class CustomInputs extends Component {
  componentDidMount() {
    this.props.scroll(this.refs.customInputs);
  }

  render() {
    return (
      <form
        ref="customInputs"
        onSubmit={e => {
          e.preventDefault();
          this.props.calculate();
        }}
        style={{ display: 'block' }}
      >
        <FilingStatus
          id="filing_status"
          name="Choose a Filing Status?"
          selected={this.props.taxpayer.filing_status}
          update={this.props.updateInput}
        />
        {this.props.taxpayer.filing_status !== undefined ? (
          <div>
            <IncomeInputs
              scroll={this.props.scroll}
              calculate={this.props.calculate}
              taxpayer={this.props.taxpayer}
              updateInput={this.props.updateInput}
            />
            <DependentsInput
              calculate={this.props.calculate}
              taxpayer={this.props.taxpayer}
              updateInput={this.props.updateInput}
            />
            <DeductionSelect
              calculate={this.props.calculate}
              deduction={this.props.deduction}
              updateDeduction={this.props.updateDeduction}
              updateInput={this.props.updateInput}
            />
            <Button type="submit" value="submit">
              Calculate
            </Button>
          </div>
        ) : null}
      </form>
    );
  }
}

export default CustomInputs;
