import React from 'react';
import styled from 'styled-components';

const StyledPlanChoice = styled.div`
  background-color: ${props => props.theme.tfBlue};
  border: 1px solid ${props => props.theme.tfBlue};
  border-radius: 4px;
  color: #fff;
  display: grid;
  font-size: 1rem;
  grid-gap: 1rem;
  grid-template: repeat(3, auto) / 1fr;
  margin: 1rem 0 0;
  padding: 1rem;

  @media screen and (min-width: ${props => props.theme.mobileWidth}) {
    grid-template: auto / repeat(3, 1fr);
    justify-content: space-evenly;
  }
`;

const StyledSelection = styled.div`
  display: grid;
  grid-gap: 0.6rem;
  grid-template: repeat(2, auto) / 1fr;
  text-align: center;

  select {
    font-family: 'Lato', sans-serif;
    font-size: 0.8rem;
    padding: 0.4rem;
    text-align: center;
  }
`;

const PlanChoice = props => {
  return (
    <StyledPlanChoice>
      <StyledSelection>
        <label htmlFor="yearSelect">Year</label>
        <select
          value={props.year}
          onChange={e => props.updateYear(e.target.value)}
          name="yearSelect"
          id="yearSelect"
        >
          {props.yearsAvailable.map(y => (
            <option key={`year-option-${y}`} value={y}>
              {y}
            </option>
          ))}
        </select>
      </StyledSelection>
      <StyledSelection>
        <label htmlFor="planSelect">Initial Plan</label>
        <select
          value={props.plan1}
          onChange={e => props.updatePlan('plan1', e.target.value)}
          name="planSelect"
          id="planSelect"
        >
          {props.plansAvailable.map(p => (
            <option key={`plan1-option-${p.id}`} value={p.id}>
              {p.name}
            </option>
          ))}
        </select>
      </StyledSelection>
      <StyledSelection>
        <label htmlFor="planSelect">Comparison Plan</label>
        <select
          value={props.plan2}
          onChange={e => props.updatePlan('plan2', e.target.value)}
          name="planSelect"
          id="planSelect"
        >
          {props.plansAvailable.map(p => (
            <option key={`plan2-option-${p.id}`} value={p.id}>
              {p.name}
            </option>
          ))}
        </select>
      </StyledSelection>
    </StyledPlanChoice>
  );
};

export default PlanChoice;
