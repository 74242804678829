import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import TableRow from './TableRow';
import Button from './Button';

const StyledTable = styled.table`
  background-color: #fff;
  border: 1px solid #999;
  border-collapse: collapse;
  font-size: 0.9rem;
  width: 100%;
`;

class TaxTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detail: false,
    };

    this.toggleDetail = this.toggleDetail.bind(this);
    this.makeTable = this.makeTable.bind(this);
  }

  toggleDetail() {
    this.setState({ detail: !this.state.detail });
  }

  makeTable(data) {
    const header = [
      {
        value: 'Line Item',
      },
      {
        value: this.props.plan1.plan.name,
      },
      {
        value: this.props.plan2.plan.name,
      },
      {
        value: 'Difference',
      },
    ];

    const tables = data.map(d => {
      return (
        <Fragment key={`table-section-${d.id}`}>
          <h2>{d.name}</h2>
          <StyledTable>
            <thead>
              <TableRow header cells={header} format="text" />
            </thead>
            <tbody>
              {d.items.map(item => {
                let cells = [];
                let meta = this.props.resultsMeta.filter(
                  r => r.id === item.id
                )[0];
                if (!meta) {
                  return null;
                }
                let plan1 = this.props.plan1.results[item.id];
                let plan2 = this.props.plan2.results[item.id];
                let format = meta.format;
                cells.push({ value: meta.name, tooltip: meta.tooltip });
                cells.push({ value: plan1 }, { value: plan2 });
                if (typeof plan1 === 'number' && typeof plan2 === 'number') {
                  cells.push({
                    value: plan2 - plan1,
                  });
                } else {
                  cells.push({ value: '' });
                }
                return (
                  <TableRow
                    key={`row-${item.id}`}
                    format={format}
                    cells={cells}
                    id={item.id}
                    desirable={meta.desirable}
                    level={item.level}
                  />
                );
              })}
            </tbody>
          </StyledTable>
        </Fragment>
      );
    });
    return tables;
  }

  render() {
    const detailItems = [
      {
        name: 'Overview of Tax Burden',
        id: 'taxes',
        items: [
          { id: 'cash_income', level: 'top' },
          { id: 'gross_income', level: 'top' },
          { id: 'agi', level: 'top' },
          { id: 'taxable_income', level: 'top' },
          { id: 'income_tax_after_credits', level: 'top' },
          { id: 'total_payroll_tax', level: 'top' },
          { id: 'tax_wedge', level: 'top' },
          { id: 'avg_effective_tax_rate', level: 'top' },
        ],
      },
      {
        name: 'Income Tax Calculation',
        id: 'tax-calc',
        items: [
          { id: 'agi', level: 'top' },
          { id: 'deductions', level: 'second' },
          { id: 'qbi_ded', level: 'third' },
          { id: 'personal_exemption_amt', level: 'second' },
          { id: 'taxable_income', level: 'top' },
          { id: 'selected_tax_before_credits', level: 'top' },
        ],
      },
      {
        name: 'Alternative Minimum Tax',
        id: 'amt',
        items: [
          { id: 'amt_taxable_income', level: 'top' },
          { id: 'amt', level: 'top' },
        ],
      },
      {
        name: 'Credits, Payments, and Other Taxes',
        id: 'other',
        items: [
          { id: 'income_tax_before_credits_with_amt', level: 'top' },
          { id: 'ctc', level: 'second' },
          { id: 'dep_credit', level: 'second' },
          { id: 'income_tax_after_nonrefundable_credits', level: 'top' },
          { id: 'niit', level: 'second' },
          { id: 'medicare_surtax', level: 'second' },
          { id: 'sched_se_tax', level: 'second' },
          { id: 'income_tax_after_other_taxes', level: 'top' },
          { id: 'actc', level: 'second' },
          { id: 'eitc', level: 'second' },
          { id: 'income_tax_after_credits', level: 'top' },
        ],
      },
    ];

    const detailTable = this.makeTable(detailItems);

    return (
      <div>
        {this.state.detail ? detailTable : null}
        <Button onClick={this.toggleDetail}>{`See ${
          this.state.detail ? 'Less' : 'More'
        } Detail`}</Button>
      </div>
    );
  }
}

export default TaxTable;
