import React from 'react';
import SimpleContainer from './SimpleContainer';
import NumericInput from './NumericInput';

const DependentsInput = props => {
  return (
    <SimpleContainer rows="1" columns="2" gridGap="1rem">
      <NumericInput
        id="child_dep"
        name="Child Dependents"
        onChange={props.updateInput}
        onEnter={props.calculate}
        placeholder={props.taxpayer.child_dep}
        tooltip="Number of children below age 17"
      />
      <NumericInput
        id="nonchild_dep"
        name="Non-Child Dependents"
        onChange={props.updateInput}
        onEnter={props.calculate}
        placeholder={props.taxpayer.nonchild_dep}
      />
    </SimpleContainer>
  );
};

export default DependentsInput;
