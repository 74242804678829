import React, { Fragment } from 'react';
import styled, { keyframes } from 'styled-components';
import Tooltip from './Tooltip';

const Animation = keyframes`
  from {
    opacity: 0;
    transform: scaleY(0)
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
`;

const Container = styled.div`
  align-content: end;
  animation: ${Animation} 0.4s;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  margin: 0 0 1rem;

  @media (max-width: ${props => props.theme.mobileWidth}) {
    grid-template: auto / auto;
  }
`;

const Label = styled.label`
  align-items: center;
  display: grid;
  font-size: 20px;
  font-weight: 700;
  grid-template-columns: ${props => (props.tooltip ? '1fr auto' : '1fr')};
`;

const Input = styled.input`
  border-radius: 4px;
  display: block;
  width: 2rem;

  &:focus {
    border: 1px solid ${props => props.theme.tfBlue};
  }
`;

/**
 * NumericInput generates a labeled input that can update state through props.
 * @param {Object} props - The properties used in the final component.
 */
const CheckboxInput = props => {
  return (
    <Fragment>
      <Container columns="2" rows="1" gridGap="0.5rem">
        <Input
          disabled={props.disabled}
          id={`input-${props.id}`}
          type="checkbox"
          onChange={event => {
            const value = event.target.checked ? props.yes : props.no;
            props.onChange(value, props.id);
          }}
          value={props.placeholder}
        />
        <Label
          tooltip={props.tooltip !== undefined}
          htmlFor={`input-${props.id}`}
        >
          {props.tooltip ? (
            <Fragment>
              <span>{props.name}</span>
              <Tooltip
                tooltipId={`tooltip-${props.id}`}
                tooltipText={props.tooltip}
              />
            </Fragment>
          ) : (
            props.name
          )}
        </Label>
      </Container>
    </Fragment>
  );
};

export default CheckboxInput;
